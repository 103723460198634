import { Button, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { grey } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { PieChart } from "@mui/x-charts";
import { GlobalState } from "context/GlobalContext";
import { brandList } from "data/businessPlanningData";
import { arrType, datatypes, inputTypes, objType } from "data/globalData";
import moment from "moment";
import { useEffect, useState } from "react";
import { allOption, replenishmentDrawerElements, replenishmentParamName, selectOptKeyName } from "../data";
import RenderInputField from "../ReusableComponents/CustomInputField/CustomInputField";
import { DataGrid } from "@mui/x-data-grid";
import { DownloadRounded } from "@mui/icons-material";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { pythonVmInstance } from "utils/AxiosInstances/RareboxPythonInstance";
import FileSaver from "file-saver";
import { useSearchParams } from "react-router-dom";
import SearchBarWithDebounce from "./SearchBarWithDebounce/SearchBarWithDebounce";
import parseErrMsgFromError from "utils/errorFunctions/parseErrorMessageFromError";

const approachesValues = [
	{ name: "Any Size Over (Last 7-15 days)" },
	{ name: "Prime Size over M & L" },
	{ name: "Higher Multiple (Category Wise)" },
	{ name: "Store Category Wise" },
	{ name: "Non Prime Size" },
	{ name: "5/2 - Nearby map SUBCAT-COLOR-OLD SEASON" },
	{ name: "Suggest Which Store needs to exit what qty" },
	{ name: "Proof of the Pudding -Replenish ST Report" },
];

const filterColumns = {
	// start_date: {
	// 	name: "Start Date",
	// 	key: "start_date",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.DATE.key,
	// 	sort_no: 1,
	// 	group: "",
	// 	placeholder: "",
	// 	default_value: "",
	// },
	// end_date: {
	// 	name: "End Date",
	// 	key: "end_date",
	// 	input_type: inputTypes.ENTER.key,
	// 	constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
	// 	data: {},
	// 	data_type: datatypes.DATE.key,
	// 	sort_no: 1,
	// 	group: "",
	// 	placeholder: "",
	// 	default_value: "",
	// },
	brand: {
		name: "Brand",
		key: "brand",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
		[selectOptKeyName]: {
			type: arrType,
			list: [
				{ name: allOption, key: allOption },
				{ name: "RARE RABBIT", key: "RARE RABBIT" },
				{ name: "RAREISM", key: "RAREISM" },
			],
		},
	},
	division: {
		name: "Division",
		key: "division",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
		[selectOptKeyName]: {
			type: arrType,
			list: [
				{ name: allOption, key: allOption },
				{ name: "Apparels", key: "APPARELS" },
				{ name: "Addons", key: "ADDONS" },
				{ name: "Footwear", key: "FOOT WEAR" },
			],
		},
	},
};
const RenderBox = ({ row, field }) => {
	return (
		<Box
			width={"100%"}
			height="100%"
			display="flex"
			alignItems="center"
			justifyContent="center"
			sx={{ background: row?.STORE === "Total" ? grey[200] : "inherit" }}>
			<Typography noWrap variant={row?.STORE === "Total" ? "h6" : "body2"}>
				{row[field]}
			</Typography>
		</Box>
	);
};

const Summary = () => {
	const { setSnackBar, snackBar, setSnackBarOpen, setLoadingOpen } = GlobalState();

	const [tableRows, setTableRows] = useState([]);
	const [selectedProfile, setSelectedProfile] = useState();
	const [searchparams, setSearchParmas] = useSearchParams();
	const [filterData, setFilterData] = useState(
		Object.keys(filterColumns)?.reduce((acc, row) => {
			acc[row] = allOption;
			return acc;
		}, {})
	);
	const columns = [
		// { field: "id", headerName: "ID", width: 90 },
		{
			field: "STORE",
			headerName: "Store",
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: ({ row, field }) => {
				return <RenderBox row={row} field={field} />;
			},
		},
		{
			field: "total_sale_7_days",
			headerName: "Last 7-Day Sale (QTY)",
			type: "number",
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: ({ row, field }) => {
				return <RenderBox row={row} field={field} />;
			},
		},
		{
			field: "total_sale_15_days",
			headerName: "Last 15-Day Sale (QTY)",
			type: "number",
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: ({ row, field }) => {
				return <RenderBox row={row} field={field} />;
			},
		},
		{
			field: "TOTAL_SOH",
			headerName: "SOH",
			type: "number",
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: ({ row, field }) => {
				return <RenderBox row={row} field={field} />;
			},
		},
		{
			field: "FINAL_ALLOCATION",
			headerName: "Final Allocation",
			type: "number",
			flex: 1,
			headerAlign: "center",
			align: "center",
			renderCell: ({ row, field }) => {
				return <RenderBox row={row} field={field} />;
			},
		},
	];
	// useEffect(() => {
	// 	if (searchparams.get(replenishmentParamName) === replenishmentDrawerElements.summary.key) onRunClick();
	// }, []);
	const onRunClick = async () => {
		if (!selectedProfile?.id) {
			setSnackBar({ ...snackBar, message: "Please select a profile", severity: "error" });
			setSnackBarOpen(true);
			return;
		}
		setLoadingOpen(true);
		try {
			const res = await pythonVmInstance.get(
				// `/replenishment/summary${Object.keys(filterData)
				// 	?.reduce((acc, row) => {
				// 		if (filterData[row] !== allOption) acc.push(row);
				// 		return acc;
				// 	}, [])
				// 	?.reduce((acc, row, i) => {
				// 		acc = acc + `${i === 0 ? "?" : "&"}${row}=${filterData[row]}`;
				// 		return acc;
				// 	}, "")}`
				`/replenishment-v2/summary?id=${selectedProfile?.id}`
			);

			if (res?.data?.result) {
				const totalCol = res?.data?.result?.reduce(
					(acc, row) => {
						columns?.forEach((col) => {
							if (!isNaN(row[col.field])) {
								if (isNaN(acc[col.field])) acc[col.field] = 0;
								acc[col.field] = +acc[col.field] + +row[col.field];
							}
						});
						return acc;
					},
					{ STORE: "Total", SITECODE: "abcd" }
				);
				console.log(totalCol, "dskjhfjdshfs");
				setTableRows([totalCol, ...res?.data?.result]);
			}
			setLoadingOpen(false);
		} catch (error) {
			if (error?.response?.data?.message) {
				setSnackBar({ ...snackBar, message: error?.response?.data?.message, severity: "error" });
				setSnackBarOpen(true);
			} else {
				setSnackBar({ ...snackBar, message: parseErrMsgFromError(error), severity: "error" });
				setSnackBarOpen(true);
			}
			setLoadingOpen(false);
		}
	};
	const onDownloadClick = async () => {
		setLoadingOpen(true);
		try {
			const res = await pythonVmInstance({
				url: `/replenishment-v2/download?id=${selectedProfile?.id}`,
				headers: {
					"Content-Type": "application/json",
				},
				method: "GET",
				responseType: "blob",
			});
			const url = URL.createObjectURL(res.data);
			FileSaver.saveAs(url, `replenishment_${new Date().toISOString().split("T")[0]}.xlsx`);
			setLoadingOpen(false);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Downloaded the file",
				severity: "success",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		} catch (error) {
			console.log(error);
			if (error?.response?.data?.message) {
				setSnackBar({ ...snackBar, message: error?.response?.data?.message, severity: "error" });
				setSnackBarOpen(true);
			} else {
				setSnackBar({ ...snackBar, message: "Something went wrong", severity: "error" });
				setSnackBarOpen(true);
			}
			setLoadingOpen(false);
		}
	};
	console.log(selectedProfile, "hdsfgsuegf");

	return (
		<Stack maxWidth="1100px" spacing={2} margin="auto">
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Typography variant="h4">Replenishment Summary</Typography>
			</Box>
			<Box display="flex" columnGap={2} flexWrap={"wrap"} justifyContent={""} rowGap={2} alignItems="end">
				{/* {Object.keys(filterColumns)
					?.map((row) => filterColumns[row])
					.map((row) => {
						return (
							<RenderInputField
								column={row}
								data={filterData}
								setData={setFilterData}
								width="24%"
								selectOptObjName={selectOptKeyName}
							/>
						);
					})} */}
				<Box width="40%">
					<SearchBarWithDebounce selectedProfile={selectedProfile} setSelectedProfile={setSelectedProfile} />
				</Box>

				<Box>
					<Button variant="contained" onClick={onRunClick} endIcon={<KeyboardArrowRightRoundedIcon />}>
						Run
					</Button>
					<Button sx={{ ml: 1 }} variant="contained" startIcon={<DownloadRounded />} onClick={onDownloadClick}>
						Download
					</Button>
				</Box>

				{/* 
				<Stack spacing={1}>
					<InputLabel>Start Date:</InputLabel>
					<TextField
						size="small"
						type="date"
						value={data?.start_date ?? ""}
						onChange={(e) => setData({ ...data, start_date: moment(e.target.value).format("YYYY-MM-DD") })}
						sx={{ width: "200px" }}
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>End Date:</InputLabel>
					<TextField
						size="small"
						type="date"
						value={data?.end_date ?? ""}
						onChange={(e) => setData({ ...data, end_date: moment(e.target.value).format("YYYY-MM-DD") })}
						sx={{ width: "200px" }}
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Brand</InputLabel>
					<Select
						value={data?.brand ?? inputTypes.SELECT.key}
						onChange={(e) => setData({ ...data, brand: e.target.value })}
						sx={{ width: "200px" }}
						size="small">
						<MenuItem value={inputTypes.SELECT.key}>{inputTypes.SELECT.name}</MenuItem>
						{brandList?.map((brand) => (
							<MenuItem key={brand.key} value={brand.key}>
								{brand.name}
							</MenuItem>
						))}
					</Select>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Season</InputLabel>
					<Select
						value={data?.season ?? inputTypes.SELECT.key}
						onChange={(e) => setData({ ...data, season: e.target.value })}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
						sx={{ width: "200px" }}
						size="small">
						<MenuItem value={inputTypes.SELECT.key}>{inputTypes.SELECT.name}</MenuItem>
						{[0, 1, 2, 3]
							?.reduce((acc, row) => {
								acc.push(`SS-${moment().get("y").toString().slice(2, 4) - 1 + row}`);
								acc.push(`AW-${moment().get("y").toString().slice(2, 4) - 1 + row}`);
								return acc;
							}, [])
							.map((brand) => (
								<MenuItem key={brand} value={brand}>
									{brand}
								</MenuItem>
							))}
					</Select>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Category</InputLabel>
					<Select
						value={data?.category ?? inputTypes.SELECT.key}
						onChange={(e) => setData({ ...data, category: e.target.value })}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
						sx={{ width: "200px" }}
						size="small">
						<MenuItem value={inputTypes.SELECT.key}>{inputTypes.SELECT.name}</MenuItem>
						<MenuItem value={"T-SHIRT"}>T-SHIRT</MenuItem>
						<MenuItem value={"SHIRT"}>SHIRT</MenuItem>
						<MenuItem value={"POLO"}>POLO</MenuItem>
						<MenuItem value={"DENIM PANT"}>DENIM PANT</MenuItem>
					</Select>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Approaches</InputLabel>
					<Select
						value={data?.approach ?? inputTypes.SELECT.key}
						onChange={(e) => setData({ ...data, approach: e.target.value })}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
						sx={{ width: "200px" }}
						size="small">
						<MenuItem value={inputTypes.SELECT.key}>{inputTypes.SELECT.name}</MenuItem>
						{approachesValues?.map((row) => {
							return (
								<MenuItem key={row.name} value={row.name}>
									{row.name}
								</MenuItem>
							);
						})}
					</Select>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Status</InputLabel>
					<Select
						value={data?.status ?? inputTypes.SELECT.key}
						onChange={(e) => setData({ ...data, approach: e.target.value })}
						MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
						sx={{ width: "200px" }}
						size="small">
						<MenuItem value={inputTypes.SELECT.key}>{inputTypes.SELECT.name}</MenuItem>
					</Select>
				</Stack> */}
			</Box>

			{/* <Box display="flex" justifyContent="space-around">
				<Box display="flex" alignItems="center" flexDirection="column">
					<PieChart
						series={[
							{
								data: [
									{ id: 0, value: 10, label: "SO" },
									{ id: 1, value: 15, label: "GIT" },
								],
							},
						]}
						margin={{ top: 10, bottom: 10, left: 10, right: 10 }}
						width={230}
						height={230}
						slotProps={{ legend: { hidden: true } }}
					/>
					<Typography variant="h6">NORTH</Typography>
				</Box>
				<Box display="flex" alignItems="center" flexDirection="column">
					<PieChart
						series={[
							{
								data: [
									{ id: 0, value: 10, label: "SO" },
									{ id: 1, value: 15, label: "GIT" },
								],
							},
						]}
						margin={{ top: 10, bottom: 10, left: 10, right: 10 }}
						width={230}
						height={230}
						slotProps={{ legend: { hidden: true } }}
					/>
					<Typography variant="h6">SOUTH</Typography>
				</Box>
				<Box display="flex" alignItems="center" flexDirection="column">
					<PieChart
						series={[
							{
								data: [
									{ id: 0, value: 10, label: "SO" },
									{ id: 1, value: 15, label: "GIT" },
								],
							},
						]}
						margin={{ top: 10, bottom: 10, left: 10, right: 10 }}
						width={230}
						height={230}
						slotProps={{ legend: { hidden: true } }}
					/>
					<Typography variant="h6">WEST</Typography>
				</Box>
				<Box display="flex" alignItems="center" flexDirection="column">
					<PieChart
						series={[
							{
								data: [
									{ id: 0, value: 10, label: "SO" },
									{ id: 1, value: 15, label: "GIT" },
								],
							},
						]}
						margin={{ top: 10, bottom: 10, left: 10, right: 10 }}
						width={230}
						height={230}
						slotProps={{ legend: { hidden: true } }}
					/>
					<Typography variant="h6">EAST</Typography>
				</Box>
			</Box> */}

			{tableRows?.length ? (
				<Box width="100%">
					<Typography variant="body2">
						<span style={{ color: "red" }}>*</span> Total Store SOH is included with GIT
					</Typography>
					{/* <Box display="flex" justifyContent="flex-end" width="100%">
					<Button variant="contained" startIcon={<DownloadRounded />} onClick={onDownloadClick}>
						Download
					</Button>
				</Box> */}
					<DataGrid
						rows={tableRows}
						getRowId={(row) => row?.SITECODE}
						columns={columns}
						sx={{
							height: "60vh",
							width: "100%",
							border: "none",
							"& .MuiDataGrid-row": {
								// borderBottom: "1px solid lightgray",
								cursor: "pointer",
								border: "none",
							},
							"& .MuiDataGrid-cell": {
								// borderBottom: "1px solid lightgray",
								padding: 0,
							},
							"& .MuiDataGrid-columnHeader": {
								cursor: "pointer",
								background: grey[300],
							},
						}}
						columnHeaderHeight={35}
						rowHeight={40}
						disableAutosize
						// disableRowSelectionOnClick
						hideFooter
					/>
				</Box>
			) : (
				<></>
			)}
		</Stack>
	);
};

export default Summary;
