import React, { createContext, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import axios from "axios";

const ReplenishmentContext = createContext();

const ReplenishmentProvider = ({ children }) => {
	const [allStores, setAllStores] = useState();

	useEffect(() => {
		const fetchAllStores = async () => {
			try {
				const {
					data: { result },
				} = await axios.get(`/api/replenishment/get_all_stores`);
				setAllStores(result);
			} catch (error) {
				console.log("Unable to fetch all stores" + ":" + error);
			}
		};
		fetchAllStores();
	}, []);

	return (
		<ReplenishmentContext.Provider
			value={{
				allStores,
				setAllStores,
			}}>
			<Outlet />
		</ReplenishmentContext.Provider>
	);
};

export const ReplenishmentState = () => {
	return useContext(ReplenishmentContext);
};

export default ReplenishmentProvider;
