import { Box, Divider, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { useState } from "react";
import CustomInputField from "../CustomInputField/CustomInputField";
import { selectOptKeyName } from "components/ReplenishmentDashboard/data";
import { datatypes } from "data/globalData";
import moment from "moment";

const DisplayInfoWithEditTab = ({ columnData, data, submitFunction, width, disableEdit = false, title = "Details" }) => {
	const [updatedData, setUpdatedData] = useState(data);
	const [modalOpen, setModalOpen] = useState(false);

	const handleCloseModal = () => {
		setUpdatedData(data);
		setModalOpen(false);
	};
	const handleModalOpen = () => {
		setModalOpen(true);
	};
	const handleFormSubmit = (e) => {
		e.preventDefault();
		let obj = {};
		if (typeof submitFunction === "function") submitFunction(obj, setModalOpen, columnData);
	};
	return (
		<Box width="100%" display="flex" flexWrap="wrap" columnGap="1%">
			<Box width="100%" justifyContent="space-between" display="flex" alignItems="center" pt={2} pb={1}>
				<Box flexGrow={1} pr={1}>
					<Typography variant="h6">{title}</Typography>
					<Divider orientation="horizontal" />
				</Box>
				<Button variant="contained" onClick={handleModalOpen} size="small" sx={{ display: disableEdit ? "none" : "block" }}>
					Edit
				</Button>
			</Box>
			{columnData?.map((labelData) => {
				const isDate = labelData?.dataType === datatypes.DATE.key;
				const value = data?.[labelData?.key]
					? isDate
						? moment(data[labelData?.key]).format("DD/MM/YYYY")
						: data[labelData?.key]
					: "";
				return (
					<Stack width="30%" spacing={0} key={labelData?.key} height={50} overflow="hidden">
						<Typography variant="subtitle2" color={grey[600]}>
							{labelData?.name}
						</Typography>
						<Tooltip title={value} placement="bottom-start">
							<Typography noWrap variant="h6" sx={{ overflow: "hidden", width: "100%", textOverflow: "ellipsis" }}>
								{value}
							</Typography>
						</Tooltip>
					</Stack>
				);
			})}

			<Dialog fullWidth maxWidth="md" open={modalOpen} onClose={handleCloseModal} component="form" onSubmit={handleFormSubmit}>
				<Box width="98%" p={2} boxSizing="border-box">
					<Stack width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
						<Stack direction="row" width="100%" flexWrap="wrap" columnGap="3%" rowGap={1}>
							<Typography textAlign="center" variant="h6" width="100%">
								Edit Details
							</Typography>
							{columnData?.map((column) => {
								return (
									<CustomInputField
										column={column}
										error={{}}
										selectOptObjName={selectOptKeyName}
										data={updatedData}
										setData={updatedData}
									/>
								);
							})}
						</Stack>
					</Stack>
				</Box>
				<DialogActions>
					<Button variant="outlined" onClick={handleCloseModal}>
						Cancel
					</Button>
					<Button autoFocus variant="contained" sx={{ mr: 1 }} type="submit">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default DisplayInfoWithEditTab;
