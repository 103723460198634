import { useNavigate } from "react-router-dom";
import { AuthState } from "context/AuthContext";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { grey } from "@mui/material/colors";
import { mboPaymentDashboardServices } from "../data";

import { admin, rootProtectedPath, services } from "data/authData";

const serviceBox = {
	display: "flex",
	p: 2,
	alignItems: "center",
	columnGap: "20px",
	border: "1px solid var(--grayBgColor)",
	borderRadius: 2,
	margin: "5px 0px",
	sx: { "&:hover": { boxShadow: 1 }, cursor: "pointer" },
};

const MboPaymentWorkingExplore = () => {
	const navigate = useNavigate();
	const { user } = AuthState();

	const handleServiceClick = (path) => {
		navigate(`/${rootProtectedPath}/${services.mbo_payment_working.key}/${path}`);
	};

	return (
		<Box maxWidth="1200px" margin="auto">
			<Box display="flex" columnGap="20px" alignItems="center">
				{/* <CategoryIcon fontSize="large" /> */}
				<Typography sx={{ width: "100%", margin: "auto" }} pt={2} pb={2} variant="h4">
					MBO Payment Working
				</Typography>
			</Box>
			{/* services list */}
			<Stack width={{ xs: "90%", sm: "100%" }} margin="auto" direction="row" flexWrap="wrap">
				{mboPaymentDashboardServices &&
					Object.keys(mboPaymentDashboardServices).map((key) => {
						const service = mboPaymentDashboardServices[key];
						return (
							<Box display="flex" flexWrap="wrap" key={service.name} columnGap={2}>
								<Typography sx={{ width: "100%", margin: "auto" }} pt={2} variant="subtitle1" color={grey[400]}>
									{service.name}
								</Typography>
								{service?.types &&
									Object.keys(service.types)
										.reduce((acc, row) => {
											if (service.types[row].display) acc.push(service.types[row]);
											return acc;
										}, [])
										.map((key) => {
											const row = key;
											return (
												<Box key={row.name} onClick={() => handleServiceClick(row.key)} {...serviceBox}>
													{row.icon}
													<Typography variant="h6">{row.name}</Typography>
												</Box>
											);
										})}
								{!service?.types && (
									<Box key={service.name} onClick={() => handleServiceClick(service.key)} {...serviceBox}>
										{service.icon}
										<Typography variant="h6">{service.name}</Typography>
									</Box>
								)}
							</Box>
						);
					})}

				{/*<Box onClick={() => navigate("/explore/own_prod_cost")} {...serviceBox}>
					<PaymentsIcon
						sx={{
							transform: "scale(1.5)",

							m: 3,
							// border: "1px solid var(--grayBgColor)",
							borderRadius: 2,
						}}
					/>
					<Typography variant="h6">Own Production Costing</Typography>
				</Box> */}
			</Stack>
		</Box>
	);
};

export default MboPaymentWorkingExplore;
