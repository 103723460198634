import { useState, useEffect, useRef, React } from "react";
import axios from "axios";
import {
	Box,
	Button,
	TextField,
	Typography,
	Grid,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Breadcrumbs,
	Stack,
	Divider,
	Paper,
	IconButton,
	InputBase,
	Tooltip,
} from "@mui/material";
import {
	Folder,
	InsertDriveFile,
	Image,
	AudioFile,
	PictureAsPdf,
	PlayCircleRounded,
	Description,
	ArrowBackRounded,
	Home,
	Search,
} from "@mui/icons-material";
import FloatingButton from "./CommonComponents/FloatingButton";
import { blue, green, grey, red } from "@mui/material/colors";
import { GlobalState } from "context/GlobalContext";
import { rootProtectedPath } from "data/authData";
import { useNavigate } from "react-router-dom";

const DesignerWorkspace = () => {
	const navigate = useNavigate();
	const [path, setPath] = useState([]); // Current path
	const [contents, setContents] = useState({ folders: [], objects: [] });
	const [folderName, setFolderName] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const [showMenu, setShowMenu] = useState(false);
	const [open, setOpen] = useState(false);
	const [status, setStatus] = useState(""); // Status message
	const [search, setSearch] = useState("");
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();

	// Reference for the hidden input element
	const fileInputRef = useRef(null);

	// Fetch contents based on path
	const fetchContents = async (currentPath) => {
		currentPath = currentPath.join("/");
		try {
			setLoadingOpen(true);
			const response = await axios.get("api/designer_workspace/list_contents", {
				params: { path: currentPath },
			});
			setContents(response.data);
			setLoadingOpen(false);
		} catch (error) {
			setLoadingOpen(false);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "error fetching contents",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			console.error("Error fetching contents:", error);
		}
	};

	useEffect(() => {
		console.log("PATH", path);
		console.log(contents.folders);
		fetchContents(path);
	}, [path]);

	// Create folder
	const handleCreateFolder = async () => {
		if (!folderName) return;
		setLoadingOpen(true);
		let newP = [...path, folderName];
		const newPath = path.length > 0 ? newP.join("/") : folderName;
		try {
			await axios.get("api/designer_workspace/create_folder", {
				params: { folderPath: newPath },
			});
			fetchContents(path); // Refresh after creation
			setFolderName("");
			setOpen(false);
			setLoadingOpen(false);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "folder created successfully",
				severity: "success",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		} catch (error) {
			console.error("Error creating folder:", error);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "unable to create folder at this moment",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setOpen(false);
			setLoadingOpen(false);
		}
	};

	// Navigate into a folder
	const handleNavigate = (folder) => {
		setPath((prev) => [...prev, folder]);
	};

	// Go back to parent folder
	const handleGoBack = () => {
		if (!path?.length) {
			navigate(`/${rootProtectedPath}`);
		}
		setPath((prev) => prev.slice(0, -1));
	};

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleMenuToggle = () => {
		setShowMenu(!showMenu);
	};

	const handleMenuUploadFiles = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click(); // Trigger the file input dialog
		}
		setShowMenu(false);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onBreadcrumbClick = (index) => {
		if (index === -1) {
			setPath([]);
		} else {
			setPath(path.slice(0, index + 1));
		}
	};

	async function generatePuclicUrl(fileName) {
		try {
			const response = await axios.get(`api/designer_workspace/signed_url`, {
				params: { fileName, path: path.join("/") },
			});
			const data = response.data;
			window.open(data.url, "_blank");
			return;
		} catch (error) {
			console.error("Error fetching signed URL:", error);
		}
	}

	const getFileType = (fileName) => {
		const extension = fileName.split(".").pop().toLowerCase();

		if (["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"].includes(extension)) {
			return "image";
		} else if (["mp4", "mkv", "webm", "avi", "mov"].includes(extension)) {
			return "video";
		} else if (["mp3", "wav", "ogg", "flac"].includes(extension)) {
			return "audio";
		} else if (["pdf"].includes(extension)) {
			return "pdf";
		} else if (["xls", "xlsx", "csv"].includes(extension)) {
			return "excel";
		} else {
			return "other";
		}
	};
	console.log("asgfdasgdagsd", contents);
	let filterFolders =
		contents?.folders?.length > 0
			? contents?.folders?.filter((folder) => folder !== "" && folder?.toLowerCase().includes(search.toLowerCase()))
			: contents?.folders;

	let filterObjects =
		contents?.objects?.length > 0
			? contents?.objects?.filter((obj) => obj !== "" && obj?.toLowerCase().includes(search.toLowerCase()))
			: contents?.objects;

	return (
		<Stack sx={{ pt: 2, pl: 2, margin: "auto" }} spacing={1}>
			<Typography variant="h5" pl={2}>
				Designer Workspace
			</Typography>
			<Box display={"flex"} columnGap={3} alignItems={"center"} pr={2}>
				{/* Go Back Button */}
				{path && (
					<Button variant="contained" onClick={handleGoBack} sx={{ ml: 2 }} startIcon={<ArrowBackRounded />} size="small">
						Back
					</Button>
				)}
				<Breadcrumbs aria-label="breadcrumb" sx={{ flexGrow: 1 }}>
					<Typography
						component={"div"}
						onClick={() => onBreadcrumbClick(-1)}
						sx={{
							color: "text.primary",
							display: "flex",
							alignItems: "center",
							fontSize: 18,
							"&:hover": path?.length ? { textDecoration: "underline", cursor: "pointer" } : {},
							color: path?.length ? "text.primary" : grey[600],
						}}
						variant="body1">
						<Home sx={{ mr: 0.5 }} fontSize="inherit" />
						Home
					</Typography>
					{path?.map((row, i) => {
						return (
							<Typography
								component={"div"}
								onClick={() => onBreadcrumbClick(i)}
								sx={{
									"&:hover": i !== path?.length - 1 ? { textDecoration: "underline", cursor: "pointer" } : {},
									color: i !== path?.length - 1 ? "text.primary" : grey[600],
									display: "flex",
									alignItems: "center",
									fontSize: 18,
								}}
								variant="body1">
								<Folder sx={{ mr: 0.5 }} fontSize="inherit" />
								{row}
							</Typography>
						);
					})}
				</Breadcrumbs>

				<Box>
					<Paper component="form" sx={{ display: "flex", alignItems: "center", width: 300 }} elevation={1}>
						<IconButton type="button" disabled sx={{ p: "10px" }} aria-label="search">
							<Search />
						</IconButton>
						<InputBase
							sx={{ ml: 1, flex: 1 }}
							placeholder="Type to search"
							inputProps={{ "aria-label": "Search Products" }}
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							// onSubmit={(e) => {
							// }}
							required
						/>
					</Paper>
				</Box>
			</Box>

			<Divider />
			{/* Grid Structure */}
			<Grid container spacing={2}>
				{/* Folders */}
				{filterFolders.length > 0 &&
					filterFolders.map((folder) => (
						<Grid item key={folder} xs={3} sm={2} md={2} lg={1}>
							<Tooltip title={folder}>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										cursor: "pointer",
										"&:hover": { color: "#1976d2" },
									}}
									onClick={() => handleNavigate(folder)}>
									<Folder sx={{ fontSize: 50, color: "#ffca28" }} />
									<Typography
										variant="body2"
										sx={{
											mt: 1,
											textAlign: "center",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
											maxWidth: "100%",
										}}>
										{folder}
									</Typography>
								</Box>
							</Tooltip>
						</Grid>
					))}

				{/* Files */}
				{filterObjects.length > 0 &&
					filterObjects.map((file) => (
						<Grid item key={file} xs={3} sm={2} md={2} lg={1}>
							<Tooltip title={file}>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										"&:hover": { color: "#1976d2" },
									}}
									onClick={() => generatePuclicUrl(file)}>
									{getFileType(file) === "image" && <Image sx={{ fontSize: 50, color: blue[400] }} />}
									{getFileType(file) === "video" && <PlayCircleRounded sx={{ fontSize: 50, color: grey[800] }} />}
									{getFileType(file) === "audio" && <AudioFile sx={{ fontSize: 50, color: "#9c27b0" }} />}
									{getFileType(file) === "pdf" && <PictureAsPdf sx={{ fontSize: 50, color: red["A700"] }} />}
									{getFileType(file) === "other" && <InsertDriveFile sx={{ fontSize: 50, color: "#9e9e9e" }} />}
									{getFileType(file) === "excel" && <Description sx={{ fontSize: 50, color: green[400] }} />}
									{/* <InsertDriveFile sx={{ fontSize: 50, color: "#9e9e9e" }} /> */}
									<Typography
										variant="body2"
										sx={{
											mt: 1,
											textAlign: "center",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
											maxWidth: "100%",
										}}>
										{file}
									</Typography>
								</Box>
							</Tooltip>
						</Grid>
					))}
			</Grid>
			<FloatingButton
				showMenu={showMenu}
				handleMenuToggle={handleMenuToggle}
				path={path}
				open={open}
				setOpen={setOpen}
				fetchContents={fetchContents}
			/>

			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Create New Folder</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						required
						fullWidth
						variant="standard"
						value={folderName}
						onChange={(e) => setFolderName(e.target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleCreateFolder}>Create</Button>
				</DialogActions>
			</Dialog>
		</Stack>
	);
};

export default DesignerWorkspace;
