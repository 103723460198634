import { Add, ClearRounded, FlashlightOffOutlined } from "@mui/icons-material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	FormControlLabel,
	IconButton,
	Radio,
	RadioGroup,
	Slider,
	Stack,
	Switch,
	Typography,
} from "@mui/material";
import CustomInputField from "components/ReplenishmentDashboard/ReusableComponents/CustomInputField/CustomInputField";
import { getColDefaultValue, isColumnDefault } from "components/EboStoreV4/data";
import { useState } from "react";
import {
	daysToreplenishCol,
	logicProfileColumns,
	logicProfileStoreGroupingAssoc,
	logicProfileToggleColumns,
	logicProfileWarehouseAssoc,
	rosStoreGradeRelationCol,
	storeGradeRosRelationtypes,
	storeGroupingtypes,
	storeMastShortNameKey,
	storeMastStoreCodeKey,
	warehouseMappingLogicColumns,
	whMappingTypeCol,
	whMapPriorityTypesColumns,
	zonesList,
} from "../data";
import { selectOptKeyName } from "components/ReplenishmentDashboard/data";
import DragDropStores from "./DragDropStores/DragDropStores";
import { constraintsOptions, inputTypes, isNewKey } from "data/globalData";
import { ReplenishmentState } from "context/ReplenishmentContext.js";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import parseErrMsgFromError from "utils/errorFunctions/parseErrorMessageFromError";
import WarehouseMappingSection from "./WarehouseMappingSection/WarehouseMappingSection";
import { ro } from "date-fns/locale";

export const fileTypesData = [
	// { name: "Industry Type Certification", Key: "INDUSTRY_CERT" },
	{ name: "Pancard", Key: "PANCARD" },
	{ name: "GST Certificate", Key: "GST_CERT" },
	{ name: "Blank Cheque Photo Copy", Key: "BLANK_CHEQUE_PHOTO" },
];

const CreateProfileModal = ({ modalOpen, setModalOpen, callBackFun = () => {} }) => {
	const { allStores } = ReplenishmentState();
	const { snackBar, setSnackBar, setSnackBarOpen, setLoadingOpen } = GlobalState();

	const defFields = (() => {
		let obj = {};
		Object.keys(logicProfileColumns)
			?.map((row) => logicProfileColumns[row])
			?.forEach((row) => (obj[row.key] = getColDefaultValue(row)));
		Object.keys(whMapPriorityTypesColumns)
			?.map((row) => whMapPriorityTypesColumns[row])
			?.forEach((row) => {
				if (obj[row.key] === whMapPriorityTypesColumns.wh_map_zone.key) obj[row.key] = true;
				else obj[row.key] = false;
			});
		Object.keys(logicProfileToggleColumns)
			?.map((row) => logicProfileToggleColumns[row])
			?.forEach((row) => {
				// if (obj[row.key] === whMapPriorityTypesColumns.wh_map_zone.key) obj[row.key] = true;
				// else
				obj[row.key] = false;
			});
		obj[whMappingTypeCol.key] = whMapPriorityTypesColumns.wh_map_zone.key;
		obj[logicProfileWarehouseAssoc] = [];
		obj[daysToreplenishCol.key] = daysToreplenishCol.default_value;
		obj[logicProfileStoreGroupingAssoc] = Object.keys(storeGroupingtypes)?.reduce((acc, row) => {
			if (row === storeGroupingtypes.to_allocate.key) acc[row] = allStores?.map((row) => row?.[storeMastStoreCodeKey]);
			else acc[row] = [];
			return acc;
		}, {});
		obj[rosStoreGradeRelationCol.key] = storeGradeRosRelationtypes.store_grade_greater_ros.key;
		return obj;
	})();

	const [modalData, setModalData] = useState({ ...defFields });

	console.log(modalData, "mdfhcnbdcdbcfbdfgvdhc");

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		setLoadingOpen(true);
		let pendingFields = [];

		Object.keys(logicProfileColumns)
			.map((row) => logicProfileColumns[row])
			.forEach((attr) => {
				let obj = modalData;
				if (isColumnDefault(attr, obj?.[attr?.key]) && attr?.constraints[constraintsOptions.NOTNULL.key])
					pendingFields.push(attr);
			});

		if (pendingFields?.length) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: `Enter values for ${pendingFields.map((row) => row?.name)}`,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);

			return;
		}
		// warehouse pending
		let whSelectPending = [];
		modalData?.[logicProfileWarehouseAssoc]?.forEach((row) => {
			Object.keys(warehouseMappingLogicColumns)
				.map((row) => warehouseMappingLogicColumns[row])
				.forEach((attr) => {
					let obj = row;
					if (isColumnDefault(attr, obj?.[attr?.key]) && attr?.constraints[constraintsOptions.NOTNULL.key])
						whSelectPending.push(attr);
				});
		});
		if (!modalData?.[logicProfileWarehouseAssoc]?.length || whSelectPending?.length) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: !modalData?.[logicProfileWarehouseAssoc]?.length
					? "Add warehouse mapping"
					: `Enter values for ${whSelectPending.map((row) => row?.name)}`,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);

			return;
		}
		try {
			const body = modalData;
			delete body["id"];
			body[logicProfileWarehouseAssoc] = body?.[logicProfileWarehouseAssoc]?.map((row) => {
				delete row["id"];
				return row;
			});
			delete body?.[logicProfileStoreGroupingAssoc].id;
			const result = await axios.post("/api/replenishment/create_logic_profile", modalData);
			if (result?.data?.status === "success") {
				callBackFun();
				handleModalclose();
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: result?.data.message,
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			} else {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: result?.data.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			}
		} catch (error) {
			setLoadingOpen(false);
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: parseErrMsgFromError(error),
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};
	const handleModalclose = () => {
		setModalOpen(false);
		setModalData({ ...defFields });
	};

	const handleToggleSwitchChange = (event, colData) => {
		const { name, value, type, checked } = event.target;
		setModalData((prev) => ({
			...prev,
			[colData?.key]: type === "checkbox" ? checked : value,
		}));
	};
	const handleRosStoreGradeRelationChange = (event) => {
		const { name, value, type, checked } = event.target;
		setModalData((prev) => ({
			...prev,
			[rosStoreGradeRelationCol?.key]: value,
		}));
	};

	const handleSliderChange = (event, newValue) => {
		setModalData((prev) => {
			return { ...prev, [daysToreplenishCol.key]: newValue };
		});
	};
	const handleWarehouseMappingDelete = (rowToDelte) => {
		setModalData((prev) => {
			return {
				...prev,
				[logicProfileWarehouseAssoc]: prev?.[logicProfileWarehouseAssoc]?.filter((row) => rowToDelte?.id !== row?.id),
			};
		});
	};
	return (
		<Dialog maxWidth="md" fullWidth open={modalOpen} onClose={handleModalclose} component="form" onSubmit={handleFormSubmit}>
			<Box p={2}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography variant="h6">Create Profile</Typography>
					<Box>
						<IconButton onClick={handleModalclose}>
							<ClearRounded />
						</IconButton>
					</Box>
				</Box>
				<Stack spacing={1} width="100%">
					<Box display="flex" columnGap={"4%"} rowGap="10px" flexWrap="wrap" width="100%">
						{Object.keys(logicProfileColumns)
							?.map((row) => logicProfileColumns[row])
							?.map((row) => {
								return (
									<CustomInputField
										column={row}
										data={modalData}
										setData={setModalData}
										width="30%"
										selectOptObjName={selectOptKeyName}
									/>
								);
							})}
					</Box>
				</Stack>
				<RadioGroup
					aria-labelledby="demo-controlled-radio-buttons-group"
					name="controlled-radio-buttons-group"
					value={modalData?.[rosStoreGradeRelationCol.key]}
					onChange={(e) => handleRosStoreGradeRelationChange(e)}>
					{Object.keys(storeGradeRosRelationtypes)
						?.map((row) => storeGradeRosRelationtypes[row])
						?.map((row) => {
							return <FormControlLabel value={row.key} control={<Radio />} label={row.name} />;
						})}
				</RadioGroup>

				{/* warehouse */}
				{/* Store Grouping */}
				<Box>
					<Typography variant="h6">Warehouse Mapping</Typography>
					<WarehouseMappingSection
						profileData={modalData}
						setProfileData={setModalData}
						disableEdit={false}
						handleDelete={handleWarehouseMappingDelete}
					/>
				</Box>
				<Box>
					<Typography variant="h6">Store Grouping</Typography>
					<DragDropStores
						setProfileData={setModalData}
						profileData={modalData}
						storeCodeList={allStores?.map((row) => row?.[storeMastStoreCodeKey])}
						storeFullDataList={allStores}
					/>
				</Box>
				<Box sx={{ mt: 2 }} display="flex" columnGap="40px">
					<Typography gutterBottom>
						{daysToreplenishCol.name}:{" "}
						<span style={{ fontWeight: "bold", fontSize: 16 }}>{modalData?.[daysToreplenishCol.key]}</span>
					</Typography>
					<Box width="40%">
						<Slider
							value={modalData?.[daysToreplenishCol.key] ?? daysToreplenishCol?.default_value}
							onChange={handleSliderChange}
							min={0}
							max={daysToreplenishCol.max_value ?? 30}
							step={1}
							valueLabelDisplay="auto"
						/>
					</Box>
				</Box>
				<Box sx={{ display: "flex", flexDirection: "column", gap: 2, maxWidth: 300 }}>
					{/* TAT Switch & Input */}
					{Object.keys(logicProfileToggleColumns)
						?.map((row) => logicProfileToggleColumns[row])
						?.map((row) => {
							return (
								<FormControlLabel
									control={
										<Switch
											checked={modalData?.[row?.key] === true}
											onChange={(e) => handleToggleSwitchChange(e, row)}
											name={row?.key}
										/>
									}
									label={row?.name}
								/>
							);
						})}
				</Box>
				<DialogActions sx={{ pt: 2 }}>
					<Button variant="outlined" sx={{ justifySelf: "center" }} onClick={handleModalclose}>
						Cancel
					</Button>
					<Button variant="contained" sx={{ justifySelf: "center" }} type="submit">
						Create
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default CreateProfileModal;
