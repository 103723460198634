import { Box, Button, Divider, FormControlLabel, Radio, RadioGroup, Slider, Stack, Switch, Typography } from "@mui/material";
import axios from "axios";
import { AuthState } from "context/AuthContext";
import { GlobalState } from "context/GlobalContext";
import { converAttrToDatagridCol, createdByColumns } from "data/globalData";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import parseErrMsgFromError from "utils/errorFunctions/parseErrorMessageFromError";
import {
	daysToreplenishCol,
	logicProfileColumns,
	logicProfileToggleColumns,
	rosStoreGradeRelationCol,
	storeGradeRosRelationtypes,
} from "../LogicProfiles/data";
import DisplayInfoWithEditTab from "../ReusableComponents/DisplayInfoWithEditTab/DisplayInfoWithEditab";
import WarehouseMappingSecDisplayWithEdit from "./WarehouseMappingSecDisplayWithEdit/WarehouseMappingSecDisplayWithEdit";
import StoreGroupingDisplayWithEdit from "./StoreGroupingDisplayWithEdit/StoreGroupingDisplayWithEdit";
import { ReplenishmentState } from "context/ReplenishmentContext.js";
import { allLogicProfilesPath } from "../data";

const SelectedLogicProfile = () => {
	const { user } = AuthState();
	const { setSnackBar, setSnackBarOpen, snackBar, setLoadingOpen, Loadingopen } = GlobalState();
	const { allStores } = ReplenishmentState();
	const navigate = useNavigate();

	const [selectedProfile, setSelectedProfile] = useState(null);
	let [searchParams, setSearchParams] = useSearchParams();
	let [settingsModal, setSettingsModal] = useState(false);

	const disableEdit = true || user?.email !== selectedProfile?.[createdByColumns[0]?.key];
	useEffect(() => {
		const fetch = async () => {
			setLoadingOpen(true);
			setSelectedProfile();
			try {
				const res = await axios.get(`/api/replenishment/get_logic_profile_by_id/${id}`);
				setSelectedProfile(res.data?.result);
				setLoadingOpen(false);
			} catch (error) {
				setSnackBarOpen(true);
				setSnackBar({ ...snackBar, message: parseErrMsgFromError(error) });
				setLoadingOpen(false);
			}
		};
		let id = searchParams.get("id");
		if (id && id !== selectedProfile?.id) fetch();
		else {
			setSelectedProfile(null);
		}
	}, [searchParams]);

	const handleSettingsModalOpen = () => {
		setSettingsModal(true);
	};
	const goToAllProfilesPath = () => {
		navigate(allLogicProfilesPath);
	};
	if (selectedProfile)
		return (
			<Stack>
				<Typography variant="h5">Selected Logic Profile: {selectedProfile?.profile_name}</Typography>
				<DisplayInfoWithEditTab
					title="BasicDetails"
					columnData={Object.keys(logicProfileColumns)?.map((row) => logicProfileColumns[row])}
					data={selectedProfile}
					setData={setSelectedProfile}
					width={"24%"}
					disableEdit={disableEdit}
				/>
				<Box maxHeight={400}>
					<WarehouseMappingSecDisplayWithEdit
						selectedProfile={selectedProfile}
						setSelectedProfile={setSelectedProfile}
						disableEdit={disableEdit}
					/>
				</Box>
				<Box maxHeight={400}>
					<StoreGroupingDisplayWithEdit
						selectedProfile={selectedProfile}
						setSelectedProfile={setSelectedProfile}
						disableEdit={disableEdit}
						storeFullDataList={allStores}
					/>
				</Box>

				{/* settings */}
				<Box>
					<Box width="100%" justifyContent="space-between" display="flex" alignItems="center" pt={2} pb={1}>
						<Box flexGrow={1} pr={1}>
							<Typography variant="h6">Settings</Typography>
							<Divider orientation="horizontal" />
						</Box>
						<Button
							variant="contained"
							onClick={handleSettingsModalOpen}
							size="small"
							sx={{ display: disableEdit ? "none" : "block" }}>
							Edit
						</Button>
					</Box>
					<Box sx={{ mt: 2 }} display="flex" columnGap="40px">
						<Typography gutterBottom>
							{daysToreplenishCol.name}:{" "}
							<span style={{ fontWeight: "bold", fontSize: 16 }}>
								{selectedProfile?.[daysToreplenishCol.key] ?? daysToreplenishCol.default_value}
							</span>
						</Typography>
						<Box width="40%">
							<Slider
								value={selectedProfile?.[daysToreplenishCol.key] ?? daysToreplenishCol?.default_value}
								min={0}
								max={daysToreplenishCol.max_value ?? 30}
								step={1}
								valueLabelDisplay="auto"
								disabled
							/>
						</Box>
					</Box>
					<RadioGroup
						aria-labelledby="demo-controlled-radio-buttons-group"
						name="controlled-radio-buttons-group"
						value={selectedProfile?.[rosStoreGradeRelationCol.key]}>
						{Object.keys(storeGradeRosRelationtypes)
							?.map((row) => storeGradeRosRelationtypes[row])
							?.map((row) => {
								return <FormControlLabel disabled value={row.key} control={<Radio />} label={row.name} />;
							})}
					</RadioGroup>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 2, maxWidth: 300 }}>
						{/* TAT Switch & Input */}
						{Object.keys(logicProfileToggleColumns)
							?.map((row) => logicProfileToggleColumns[row])
							?.map((row) => {
								return (
									<FormControlLabel
										control={
											<Switch
												checked={selectedProfile?.[row?.key] === true}
												// onChange={(e) => handleToggleSwitchChange(e, row)}
												name={selectedProfile?.[row?.key]}
											/>
										}
										disabled
										label={row?.name}
									/>
								);
							})}
					</Box>
				</Box>
			</Stack>
		);
	else {
		if (!Loadingopen)
			return (
				<Stack display="flex" justifyContent="center" alignItems="center" height="100%" spacing={1}>
					<Typography variant="h5" p={2}>
						---- No Profile Id Found ----
					</Typography>
					<Button variant="contained" sx={{ textTransform: "capitalize" }} onClick={goToAllProfilesPath}>
						Go to All Profiles
					</Button>
				</Stack>
			);
	}
};

export default SelectedLogicProfile;
