import {
	Box,
	Divider,
	FormControlLabel,
	List,
	ListItem,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { useState } from "react";
import CustomInputField from "components/ReplenishmentDashboard/ReusableComponents/CustomInputField/CustomInputField";
import { selectOptKeyName } from "components/ReplenishmentDashboard/data";
import { datatypes } from "data/globalData";
import moment from "moment";
import {
	logicProfileWarehouseAssoc,
	warehouseMappingLogicColumns,
	whMappingTypeCol,
	whMapPriorityTypesColumns,
} from "components/ReplenishmentDashboard/LogicProfiles/data";
import WarehouseMappingSection from "components/ReplenishmentDashboard/LogicProfiles/CreateProfileModal/WarehouseMappingSection/WarehouseMappingSection";

const WarehouseMappingSecDisplayWithEdit = ({ selectedProfile, setSelectedProfile, submitFunction, disableEdit = false }) => {
	const [updatedData, setUpdatedData] = useState(selectedProfile);
	const [modalOpen, setModalOpen] = useState(false);

	const handleCloseModal = () => {
		setUpdatedData(selectedProfile);
		setModalOpen(false);
	};
	const handleModalOpen = () => {
		setModalOpen(true);
	};
	const handleFormSubmit = (e) => {
		e.preventDefault();
		let obj = {};
		if (typeof submitFunction === "function") submitFunction(obj, setModalOpen);
	};
	return (
		<Box width="100%" display="flex" flexWrap="wrap" columnGap="1%">
			<Box width="100%" justifyContent="space-between" display="flex" alignItems="center" pt={2} pb={1}>
				<Box flexGrow={1} pr={1}>
					<Typography variant="h6">Warehouse Mapping</Typography>
					<Divider orientation="horizontal" />
				</Box>
				<Button variant="contained" onClick={handleModalOpen} size="small" sx={{ display: disableEdit ? "none" : "block" }}>
					Edit
				</Button>
			</Box>
			<RadioGroup
				aria-labelledby="demo-controlled-radio-buttons-group"
				name="controlled-radio-buttons-group"
				row
				value={selectedProfile?.[whMappingTypeCol.key]}
				onChange={() => {}}>
				{Object.keys(whMapPriorityTypesColumns)
					?.map((row) => whMapPriorityTypesColumns[row])
					?.map((row) => {
						return <FormControlLabel disabled value={row.key} control={<Radio />} label={row.name} />;
					})}
			</RadioGroup>

			{selectedProfile?.[logicProfileWarehouseAssoc]?.map((rowData) => {
				return (
					<Box width="100%" display="flex">
						<Box display="flex" columnGap={"2%"} rowGap="10px" flexWrap="wrap" width="100%">
							{Object.keys(warehouseMappingLogicColumns)
								?.map((row) => warehouseMappingLogicColumns[row])
								?.map((row) => {
									const isWhCol = row?.key === warehouseMappingLogicColumns?.warehouse.key;
									const width = isWhCol ? "24%" : "16%";
									const value = rowData?.[row?.key] ? rowData?.[row?.key] : isWhCol ? "" : [];
									return (
										<Stack width={width} spacing={0} key={row?.key} overflow="hidden">
											<Typography variant="subtitle2" color={grey[600]}>
												{row?.name}
											</Typography>
											{isWhCol ? (
												<Tooltip title={value} placement="bottom-start">
													<Typography
														noWrap
														variant="h6"
														sx={{ overflow: "hidden", width: "100%", textOverflow: "ellipsis" }}>
														{value}
													</Typography>
												</Tooltip>
											) : (
												<Stack>
													{value?.map((row) => {
														return (
															<Typography variant="subtitle2" color={grey[800]} key={row}>
																{row}
															</Typography>
														);
													})}
												</Stack>
											)}
										</Stack>
									);
								})}
						</Box>
					</Box>
				);
			})}
			{modalOpen && (
				<Dialog
					fullWidth
					maxWidth="md"
					open={modalOpen}
					onClose={handleCloseModal}
					component="form"
					onSubmit={handleFormSubmit}>
					<Box width="98%" p={2} boxSizing="border-box" minHeight={300}>
						<Stack width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
							<Stack direction="row" width="100%" flexWrap="wrap" rowGap={1}>
								<Typography textAlign="center" variant="h6" width="100%">
									Edit Details
								</Typography>
								<Box width="100%">
									<WarehouseMappingSection
										profileData={updatedData}
										setProfileData={setUpdatedData}
										disableEdit={false}
									/>
								</Box>
							</Stack>
						</Stack>
					</Box>

					<DialogActions>
						<Button variant="outlined" onClick={handleCloseModal}>
							Cancel
						</Button>
						<Button autoFocus variant="contained" sx={{ mr: 1 }} type="submit">
							Save
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Box>
	);
};

export default WarehouseMappingSecDisplayWithEdit;
