import axios from "axios";

export const pythonVmInstance = axios.create({
	baseURL: process.env.REACT_APP_PYTHON_APP_API_URL,
});

pythonVmInstance.interceptors.request.use((config) => {
	const token = localStorage.getItem("py_token"); // or from context/state
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});
