import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import { useState } from "react";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
	const { setSnackBar, snackBar, setSnackBarOpen, rootPath } = GlobalState();
	const navigate = useNavigate();

	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const formStack = {
		width: "80%",
	};
	const onFormSubmit = async (e) => {
		e.preventDefault();
		const search = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
		let regex = new RegExp(search, "i");
		if (!regex.test(newPassword)) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message:
					"Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.",
				severity: "error",
			});
			return;
		}
		try {
			await axios.post("/api/auth/change_password", { currentPassword, newPassword }).then((res) => {
				let data = res.data;
				console.log(data);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: data.message,
					severity: data.error ? "error" : "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				if (data.status === "success") {
					navigate(rootPath);
				}
			});
		} catch (error) {
			setSnackBarOpen(true);
			let message = "Internal Server Error";
			if (error.response) message = error.response.data.message;
			setSnackBar({
				...snackBar,
				message,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
		}
	};

	return (
		<Box
			width="100%"
			height="100%"
			display="flex"
			alignItems="Center"
			justifyContent="center"
			component="form"
			onSubmit={onFormSubmit}>
			<Stack width="30%" height="30%" alignItems="center" spacing={2} pt={4}>
				<Typography textAlign="center" variant="h5">
					Change Password
				</Typography>
				<Stack sx={formStack} spacing={1}>
					<InputLabel>Current Password:</InputLabel>
					<TextField
						size="small"
						type="text"
						value={currentPassword}
						onChange={(e) => setCurrentPassword(e.target.value)}
						placeholder="Enter current Password"
						required
					/>
				</Stack>
				<Stack sx={formStack} spacing={1}>
					<InputLabel>New Password:</InputLabel>
					<TextField
						size="small"
						type="text"
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
						placeholder="Enter New Password"
						required
					/>
				</Stack>
				<Box alignSelf="center">
					<Button variant="contained" type="submit" sx={{ width: 150 }}>
						Submit
					</Button>
				</Box>
			</Stack>
		</Box>
	);
};

export default ChangePassword;
