import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { webDataDrawerElements } from "data/radhamaniPageData";
import { useNavigate, useOutletContext } from "react-router-dom";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import Summary from "./Summary/Summary";
import FileUpload from "./FileUpload/FileUpload";
import { replenishmentDrawerElements } from "./data";
import LogicProfiles from "./LogicProfiles/LogicProfiles";
import SelectedLogicProfile from "./SelectedLogicProfile/SelectedLogicProfile";

const ReplenishmentDashboard = () => {
	const [tab, setTab] = useOutletContext();
	const navigate = useNavigate();
	const tabComponents = [
		{
			...replenishmentDrawerElements.logic_profiles,
			component: <LogicProfiles setTab={setTab} />,
		},
		{
			...replenishmentDrawerElements.summary,
			component: <Summary />,
		},
		{
			...replenishmentDrawerElements.upload_files,
			component: <FileUpload />,
		},
		{
			...replenishmentDrawerElements.selected_profile,
			component: <SelectedLogicProfile />,
		},
	];
	return (
		<Box sx={{ width: "100%" }}>
			<Button startIcon={<KeyboardBackspaceRounded />} variant="outlined" onClick={() => navigate("/explore")} sx={{ m: 2 }}>
				Back
			</Button>
			{tabComponents &&
				tabComponents.map((row) => (
					<Box width="95%" margin="auto" key={row.key} display={row.key === tab ? "block" : "none"}>
						{row.component}
					</Box>
				))}
		</Box>
	);
};

export default ReplenishmentDashboard;
