import { Button, CircularProgress, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import { grey, lime } from "@mui/material/colors";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { PieChart } from "@mui/x-charts";
import { GlobalState } from "context/GlobalContext";
import { arrType, converAttrToDatagridCol, createdByColumns, datatypes, inputTypes, objType, primaryKeyColumn } from "data/globalData";
import { useCallback, useEffect, useRef, useState } from "react";
import { allOption, replenishmentDrawerElements, replenishmentParamName, selectOptKeyName } from "../data";
import { DataGrid } from "@mui/x-data-grid";
import { Add, DownloadRounded } from "@mui/icons-material";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { logicProfileColumns } from "./data";
import CreateProfileModal from "./CreateProfileModal/CreateProfileModal";
import axios from "axios";
import parseErrMsgFromError from "utils/errorFunctions/parseErrorMessageFromError";
import DatagridCustomActions from "./DatagridCustomActions/DatagridCustomActions";
import _ from "lodash";
import { rootProtectedPath, services } from "data/authData";

const recordsLimit = 20;

const fetchRows = (offset, limit) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			const newRows = Array.from({ length: limit }, (_, index) => ({
				id: offset + index + 1,
				profile_name: `User ${offset + index + 1}`,
				age: Math.floor(Math.random() * 50) + 20,
			}));
			resolve(newRows);
		}, 1000);
	});
};

const LogicProfiles = ({ setTab }) => {
	const { setSnackBar, snackBar, setSnackBarOpen, setLoadingOpen } = GlobalState();
	const location = useLocation();
	const navigate = useNavigate();

	const [tableRows, setTableRows] = useState([]);
	const [createModal, setCreateModal] = useState(false);
	const [searchparams, setSearchParams] = useSearchParams();
	const [loading, setLoading] = useState(false);
	const [offset, setOffset] = useState(0);
	const [hasMore, setHasMore] = useState(true);

	const gridRef = useRef(null); // Ref to track DataGrid scroll

	const columns = [
		converAttrToDatagridCol(primaryKeyColumn),
		...Object.keys(logicProfileColumns)?.map((row) => converAttrToDatagridCol(logicProfileColumns[row])),
		...createdByColumns.map((row) => converAttrToDatagridCol(row)),
		{
			field: "actions",
			headerName: "Actions",
			align: "center",
			headerAlign: "center",
			sortable: false,
			renderCell: (params) => <DatagridCustomActions row={params.row} />,
			width: 100,
		},
	];

	const fetchLogicProfiles = async (offset, recordsLimit) => {
		try {
			const res = await axios.get(`/api/replenishment/get_logic_profiles?limit=${recordsLimit}&offset=${offset}`);

			if (res?.data?.result) {
				return res?.data?.result;
			} else return [];
		} catch (err) {
			console.log(err);
			setSnackBar({ ...snackBar, message: parseErrMsgFromError(err), severity: "error" });
			setSnackBarOpen(true);
			setLoadingOpen(false);

			return [];
		}
	};
	const loadMoreData = useCallback(
		_.throttle(async () => {
			if (loading || !hasMore) return;
			if (offset === 0) {
				setLoadingOpen(true);
			} else setLoading(true);

			const newRows = await fetchLogicProfiles(offset, recordsLimit);
			setTableRows((prev) => [...prev, ...newRows]);

			setOffset((prevOffset) => prevOffset + recordsLimit);

			if (offset < recordsLimit) setLoadingOpen(false);
			else setLoading(false);

			if (newRows.length < recordsLimit) {
				setHasMore(false); // Stop loading if no more data
			}
		}, 1000), // Throttled to run at most once per second
		[loading, offset, hasMore]
	);
	useEffect(() => {
		const fetch = async () => {
			if (searchparams.get(replenishmentParamName) === replenishmentDrawerElements.logic_profiles.key) {
				setLoadingOpen(true);
				let arr = await fetchLogicProfiles(offset, recordsLimit);
				if (arr) {
					setTableRows(arr);
					setLoadingOpen(false);
				}
			}
		};
		tableRows?.length <= 0 && fetch();
	}, [location.search]);

	// Attach scroll event to detect when reaching bottom
	useEffect(() => {
		console.log("loading second useeffect");

		const handleScroll = () => {
			const gridContainer = gridRef.current?.querySelector(".MuiDataGrid-virtualScroller");
			if (!gridContainer) return;

			const { scrollTop, scrollHeight, clientHeight } = gridContainer;
			if (scrollHeight - scrollTop <= clientHeight + 20) {
				loadMoreData(); // Load more when near bottom
			}
		};

		const gridContainer = gridRef.current?.querySelector(".MuiDataGrid-virtualScroller");
		if (gridContainer) {
			gridContainer.addEventListener("scroll", handleScroll);
		}

		return () => {
			if (gridContainer) {
				gridContainer.removeEventListener("scroll", handleScroll);
			}
		};
	}, [loadMoreData]);

	const handleCreateModalOpen = () => {
		setCreateModal(true);
	};
	const handleProfileSelect = (row) => {
		setSearchParams((params) => {
			params.set(replenishmentParamName, replenishmentDrawerElements.selected_profile.key);
			params.set("id", row.id);
			return params;
		});
		setTab(replenishmentDrawerElements.selected_profile.key);
	};
	return (
		<Stack maxWidth="1100px" spacing={2} margin="auto">
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Typography variant="h4">Logic Profiles</Typography>
				<Box>
					<Button variant="contained" startIcon={<Add />} onClick={handleCreateModalOpen}>
						Create
					</Button>
				</Box>
			</Box>

			<Box width="100%" ref={gridRef}>
				<DataGrid
					rows={tableRows}
					// getRowId={(row) => row?.SITECODE}
					columns={columns}
					sx={{
						height: "60vh",
						width: "100%",
						border: "none",
						"& .MuiDataGrid-row": {
							// borderBottom: "1px solid lightgray",
							cursor: "pointer",
							border: "none",
						},
						"& .MuiDataGrid-cell": {
							// borderBottom: "1px solid lightgray",
							padding: 0,
						},
						"& .MuiDataGrid-columnHeader": {
							cursor: "pointer",
							background: grey[300],
						},
					}}
					columnHeaderHeight={35}
					rowHeight={40}
					disableAutosize
					disableRowSelectionOnClick
					pagination={false} // Remove footer
					hideFooter={true}
					disableColumnMenu
					disableColumnFilter
					disableColumnSelector
					onRowClick={({ row }) => handleProfileSelect(row)}
				/>
				{loading && (
					<Box display="flex" sx={{ background: grey[400], borderRadius: 2, p: 1 }} alignItems="center">
						<Typography variant="body2" color={grey[900]}>
							Loading...{" "}
						</Typography>
						<CircularProgress size="18px" sx={{ transform: "scale(0.4)" }} />
					</Box>
				)}
			</Box>
			{createModal && <CreateProfileModal modalOpen={createModal} setModalOpen={setCreateModal} />}
		</Stack>
	);
};

export default LogicProfiles;
