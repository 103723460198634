import { Box, Button, FormControlLabel, IconButton, Radio, RadioGroup, Typography } from "@mui/material";
import {
	logicProfileWarehouseAssoc,
	storeMastShortNameKey,
	storeMastStoreCodeKey,
	warehouseMappingLogicColumns,
	whMappingTypeCol,
	whMapPriorityTypesColumns,
	zonesList,
} from "../../data";
import { useState } from "react";
import { getColDefaultValue } from "components/EboStoreV4/data";
import { inputTypes, isNewKey } from "data/globalData";
import { ReplenishmentState } from "context/ReplenishmentContext.js";
import { selectOptKeyName } from "components/ReplenishmentDashboard/data";
import { Add, DeleteRounded } from "@mui/icons-material";
import CustomInputField from "components/ReplenishmentDashboard/ReusableComponents/CustomInputField/CustomInputField";

const WarehouseMappingSection = ({ profileData, setProfileData, disableEdit = false, handleDelete = (row) => {} }) => {
	const { allStores } = ReplenishmentState();

	const defWhMappingFields = (() => {
		let obj = {};
		Object.keys(warehouseMappingLogicColumns)
			?.map((row) => warehouseMappingLogicColumns[row])
			?.forEach((row) => (obj[row.key] = getColDefaultValue(row)));
		return obj;
	})();
	const updatedWarehouseColumns = (ColDataObj, whProirityType) => {
		let list = [];
		if (whProirityType === whMapPriorityTypesColumns.wh_map_store.key)
			list = allStores?.map((row) => ({
				name: row?.[storeMastShortNameKey],
				key: row?.[storeMastStoreCodeKey],
			}));
		else if (whProirityType === whMapPriorityTypesColumns.wh_map_state.key)
			list = allStores
				?.reduce((acc, row) => {
					if (!acc?.includes(row?.["State"])) acc?.push(row?.["State"]);
					return acc;
				}, [])
				?.map((state) => ({
					name: state,
					key: state,
				}));
		else if (whProirityType === whMapPriorityTypesColumns.wh_map_zone.key)
			list = Object.keys(zonesList)?.map((row) => zonesList[row]);
		return Object.keys(ColDataObj)
			?.map((row) => ColDataObj[row])
			?.reduce((acc, row) => {
				if (row?.key === warehouseMappingLogicColumns.warehouse.key) acc[row?.key] = row;
				else {
					acc[row?.key] = {
						...row,
						[selectOptKeyName]: {
							...row[selectOptKeyName],
							list,
						},
					};
				}
				return acc;
			}, {});
	};

	const [warehouseMappingColumns, setWarehouseMappingColumns] = useState(
		updatedWarehouseColumns(
			warehouseMappingLogicColumns,
			profileData?.[whMappingTypeCol.key] ?? whMapPriorityTypesColumns.wh_map_zone.key
		)
	);

	const handleAddWarehouseRowClick = () => {
		setProfileData((prev) => {
			return {
				...prev,
				[logicProfileWarehouseAssoc]: [
					...prev?.[logicProfileWarehouseAssoc],
					{ ...defWhMappingFields, [isNewKey]: true, id: new Date().valueOf() },
				],
			};
		});
	};
	const handleWarehouseMappingDataChange = (currentRow) => {
		return (data) => {
			setProfileData((prev) => {
				return {
					...prev,
					[logicProfileWarehouseAssoc]: prev?.[logicProfileWarehouseAssoc]?.map((row) => {
						if (row.id === currentRow?.id) {
							return { ...row, ...data };
						}
						return row;
					}),
				};
			});
		};
	};

	const handleWhMapRadioChange = (e) => {
		setProfileData((prev) => {
			return { ...prev, [whMappingTypeCol.key]: e.target.value, [logicProfileWarehouseAssoc]: [] };
		});
		setWarehouseMappingColumns((prev) => updatedWarehouseColumns(prev, e.target.value));
	};

	return (
		<Box width="100%">
			<Box display="flex" alignItems="center" columnGap="10px">
				<RadioGroup
					aria-labelledby="demo-controlled-radio-buttons-group"
					name="controlled-radio-buttons-group"
					row
					value={profileData?.[whMappingTypeCol.key]}
					onChange={handleWhMapRadioChange}>
					{Object.keys(whMapPriorityTypesColumns)
						?.map((row) => whMapPriorityTypesColumns[row])
						?.map((row) => {
							return <FormControlLabel disabled={disableEdit} value={row.key} control={<Radio />} label={row.name} />;
						})}
				</RadioGroup>
				<Box display={disableEdit ? "none" : "block"}>
					<Button variant="contained" size="small" startIcon={<Add />} onClick={handleAddWarehouseRowClick}>
						Add Warehouse
					</Button>
				</Box>
			</Box>

			{profileData?.[logicProfileWarehouseAssoc]?.map((rowData) => {
				return (
					<Box width="100%" display="flex">
						<Box display="flex" columnGap={"2%"} rowGap="10px" flexWrap="wrap" width="100%">
							{Object.keys(warehouseMappingColumns)
								?.map((row) => warehouseMappingColumns[row])
								?.map((row) => {
									if (disableEdit) row = { ...row, input_type: inputTypes.AUTO_GENERATE.key };
									return (
										<CustomInputField
											column={row}
											data={rowData}
											setData={handleWarehouseMappingDataChange(rowData)}
											width={row?.key === warehouseMappingColumns?.warehouse.key ? "21%" : "15%"}
											selectOptObjName={selectOptKeyName}
										/>
									);
								})}
							<Box alignSelf="flex-end" display={disableEdit ? "none" : "block"}>
								<IconButton onClick={() => handleDelete(rowData)}>
									<DeleteRounded />
								</IconButton>
							</Box>
						</Box>
					</Box>
				);
			})}
		</Box>
	);
};

export default WarehouseMappingSection;
