import { arrType, datatypes, inputTypes } from "data/globalData";

const SCHEME_TYPE_OPTIONS = [
	{
		name: "Buy Certain Value & Get Certain Value Off",
		key: "Buy_Certain_Value_Get_Certain_Value_Off",
	},
	{
		name: "Buy Certain Quantity & Get Certain Quantity Free",
		key: "Buy_Certain_Quantity_Get_Certain_Quantity_Free",
	},
	{
		name: "Buy Certain Amount & Get Certain Percent Off",
		key: "Buy_Certain_Amount_Get_Certain_Percent_Off",
	},
];

export const BRAND_OPTIONS = [
	{
		name: "RARE RABBIT",
		key: "RARE RABBIT",
		brand_code: "RR",
	},
	{
		name: "RAREISM",
		key: "RAREISM",
		brand_code: "RSM",
	},
	{
		name: "THOR",
		key: "THOR",
		brand_code: "THOR",
	},
	{
		name: "RAREONES",
		key: "RAREONES",
		brand_code: "RO",
	},
	{
		name: "RAREZ",
		key: "RAREZ",
		brand_code: "RZ",
	},
];

export const master_general_information_fields = [
	{
		name: "Scheme Type",
		key: "Scheme_type",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 0,
		group: "",
		option_data: {
			type: arrType,
			list: SCHEME_TYPE_OPTIONS,
		},
	},
	{
		name: "Scheme Name",
		key: "scheme_name",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 0,
		group: "",
	},
	{
		name: "Scheme ID",
		key: "scheme_id",
		input_type: inputTypes.AUTO_GENERATE.key,
		constraints: { NOTNULL: true, DISPLAY: false, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Brand",
		key: "brand",
		input_type: inputTypes.MULTIPLE_SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		option_data: {
			type: arrType,
			list: BRAND_OPTIONS,
		},
	},
	{
		name: "Scheme Description",
		key: "scheme_description",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.LONGTEXT.key,
		sort_no: 3,
		group: "",
	},
];

export const date_availability_fields = [
	{
		name: "Start Date",
		key: "start_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 0,
		group: "",
	},
	{
		name: "End Date",
		key: "end_date",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.DATE.key,
		sort_no: 0,
		group: "",
	},
];

export const buy_value_get_value = [
	{
		name: "Buy",
		key: "buy",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 0,
		group: "",
	},
	{
		name: "Get",
		key: "get",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.NUMBER.key,
		sort_no: 0,
		group: "",
	},
];

export const store_availability_fields = [
	{
		name: "Zone",
		key: "zone",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 0,
		group: "",
	},
	{
		name: "State",
		key: "state",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 0,
		group: "",
	},
	{
		name: "City",
		key: "city",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 0,
		group: "",
	},
	{
		name: "Distributer",
		key: "distributer",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 0,
		group: "",
	},
];
