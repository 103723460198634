import { Box, Button, Divider, InputLabel, Stack, Typography } from "@mui/material";
import { EboStoreState } from "context/EboStoreContext";
import {
	addOrEditModelAction,
	eboAllStoresPage,
	eboAllStorespageUrl,
	getColumnValue,
	leaseDetails,
	storeBasicDetailsFields,
	storeCapexTableColumns,
	storeLeaseAgreeAssocObj,
	storeTypeDetails,
} from "../../../data";
import { inputTypes, statusOptions } from "data/pimData";
import { datatypes } from "data/globalData";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import LeaseAgreement from "./LeaseAgreement/LeaseAgreement";
import MboLfsAgreement from "./MboLfsAgreement/MboLfsAgreement";
import MboLfsOtherDetails from "./MboLfsOtherDetails/MboLfsOtherDetails";
import { useNavigate, useSearchParams } from "react-router-dom";
import { rootProtectedPath, services } from "data/authData";
import BillerDetailsTable from "./BillerDetailsTable/BillerDetailsTable";
import LiscenceTable from "./LiscenceTable/LiscenceTable";
import CamTable from "./CamTable/CamTable";
import RentRevenueTable from "./RentRevenueTable/RentRevenueTable";
import DepositTable from "./DepositTable/DepositTable";
import StoreInsuranceTable from "./StoreInsuranceTable/StoreInsuranceTable";
import StoreTypeDetails from "./StoreTypeDetails/StoreTypeDetails";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import BasicDetailsModal from "./BasicDetails/AddOrEditModal/AddOrEditModal";
import RenovationTable from "./RenovationTable/RenovationTable";
import CapexTablePopup from "./CapexTable/AddOrEditModal/AddOrEditModal";
import { FileUpload } from "@mui/icons-material";
import FileUploadTable from "./FileUploadTable/FileUploadTable";
import AreaDetailsTable from "./AreaDetails/AreaDetailsTable";

const BasicDetails = () => {
	const { selectedStore, setSelectedStore } = EboStoreState();
	const [selectedLease, setSelectedLease] = useState(selectedStore);
	const [basicDetailModal, setBasicDetailModal] = useState(false);
	const [areaModal, setAreaDetailModal] = useState(false);
	const [capexModal, setCapexModal] = useState(false);
	const [filesDetails, setFilesDetails] = useState([]);
	let [searchParams, setSearchParams] = useSearchParams();
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();
	const navigate = useNavigate();
	const goToStoresPage = () => {
		navigate(eboAllStorespageUrl);
	};

	useEffect(() => {
		console.log("selectedSTORE", selectedStore);

		const fetch = async (store_code) => {
			try {
				setLoadingOpen(true);
				const res = await axios.get(`/api/ebo_store_v4/store_by_id/${store_code}`);
				console.log(res.data);
				setSelectedStore(res.data.store);
				setFilesDetails(res.data.files);
				setLoadingOpen(false);
			} catch (error) {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Unable to get store details",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			}
		};
		let store_code = searchParams.get("store");
		if (store_code) fetch(store_code);
		else {
			setSelectedStore(null);
		}
	}, [searchParams]);

	const handleGetStoreFiles = async (tableName, rowId, callBack) => {
		setLoadingOpen(true);
		await axios
			.get(`/api/ebo_store_v4/get_store_files/${tableName}/${rowId}`)
			.then((res) => {
				if (res.status === 200) {
					console.log("res.data", res.data);
					callBack(res.data);
					setLoadingOpen(false);
				}
			})
			.catch((err) => {
				console.log("error", err);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: `unable to get stores files`,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			});
	};

	const handleFileUpload = async (files, store_code, tableName, callBack) => {
		setLoadingOpen(true);
		let form = new FormData();
		form.append("file", files);
		form.append("store_code", store_code);
		form.append("tableName", tableName);
		await axios
			.post("/api/ebo_store_v4/ebo_store_file_upload", form, {
				headers: { "Content-Type": "multipart/form-data" },
			})
			.then((res) => {
				let { name, publicUrl } = res.data;
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "File uploaded successfully",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				callBack(publicUrl);
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: err.message,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				return "Error in file Upload";
			});
	};

	const handleFileUploadDetails = async (files, storeCode, tableName) => {
		let updatedData = [];
		const uploadPromises = files.map(async (data) => {
			try {
				const callBack = (res) => {
					console.log("updatedData", res);
					updatedData.push({ ...data, url: res });
				};
				let result = await handleFileUpload(data.file, storeCode, tableName, callBack);
				console.log("File upload result:", result); // Ensure the result is logged
			} catch (error) {
				console.error("Error in file upload:", error);
			}
		});
		await Promise.all(uploadPromises);
		return updatedData;
	};
	const handleCreateDetails = async (type, body, callBackFun) => {
		setLoadingOpen(true);
		await axios
			.post(`/api/ebo_store_v4/create_new_store_details/${type}`, body)
			.then((res) => {
				if (res.status === 200) {
					console.log("res.data", res.data?.result);
					callBackFun(res.data?.result);
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: `Data created ${type}`,
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					setLoadingOpen(false);
				}
			})
			.catch((err) => {
				console.log("error", err);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: `unable to create ${type} stores details`,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			});
	};
	const handleUpdateDetails = async (type, body, callBackFun) => {
		setLoadingOpen(true);
		await axios
			.put(`/api/ebo_store_v4/update_store_details/${type}`, body)
			.then((res) => {
				if (res.status === 200) {
					callBackFun();
					setLoadingOpen(false);
				}
			})
			.catch((err) => {
				console.log("error", err);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "unable to fetch stores",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				setLoadingOpen(false);
			});
	};
	// console.log("3456dfghtrewsddfg", selectedStore);
	let isEbo = selectedStore?.eboStoreTypeDetails[0]?.channel === "EBO" ? true : false;
	if (selectedStore)
		return (
			<Stack p={1} width="98%" margin="auto" spacing={2}>
				<Box display="flex" justifyContent="space-between" pt={1} pb={2}>
					<Typography variant="h5">
						{/* <Button startIcon={<ArrowBackIosIcon fontSize="20" />} size="large" onClick={() => navigate(-1)} /> */}
						Store Details - {selectedStore?.store_code} - {selectedStore?.lf_number}
					</Typography>
					{/* <Box>
						<Button variant="contained">Publish</Button>
					</Box> */}
					<Typography color={selectedStore.status === statusOptions.PUBLISHED.key && statusOptions.PUBLISHED.color}>
						{selectedStore.status === statusOptions.PUBLISHED.key && statusOptions.PUBLISHED.key}
					</Typography>
				</Box>

				<StoreTypeDetails
					typeData={selectedStore?.eboStoreTypeDetails?.length ? selectedStore?.eboStoreTypeDetails[0] : []}
					createDetailsApi={handleCreateDetails}
					selectedStore={selectedStore}
					setSelectedStore={setSelectedStore}
				/>
				{/* Basic Details */}
				<Box display="flex" flexWrap="wrap" columnGap={"10px"} rowGap="10px" width="100%">
					<Box display="flex" flexWrap="wrap" width="100%" justifyContent="space-between">
						<Typography variant="button">Basic Details</Typography>
						<Box>
							<Button
								size="small"
								disabled={selectedStore.status === statusOptions.PUBLISHED.key}
								variant="contained"
								onClick={() => setBasicDetailModal(true)}>
								Edit
							</Button>
						</Box>
						<Divider sx={{ width: "100%" }} />
					</Box>
					{storeBasicDetailsFields?.map((row) => {
						return (
							<Stack className="vendor-input" spacing={0} width={"23%"}>
								<InputLabel>
									{row?.name}
									{/* {field?.required ? <span style={{ color: "red" }}>*</span> : ""} */}
								</InputLabel>
								<>
									<Typography variant="h6" fontSize={16}>
										{getColumnValue(
											row,
											selectedStore?.eboStoreBasicDetails ? selectedStore?.eboStoreBasicDetails : {}
										)}
									</Typography>
								</>
							</Stack>
						);
					})}
				</Box>
				{/* Besic details add or edit modal */}
				{basicDetailModal && (
					<BasicDetailsModal
						modal={basicDetailModal}
						setModal={setBasicDetailModal}
						type={addOrEditModelAction.UPDATE}
						defaultData={selectedStore?.eboStoreBasicDetails}
						handleUpdateDetails={handleUpdateDetails}
						selectedStore={selectedStore}
						setSelectedStore={setSelectedStore}
					/>
				)}

				{isEbo && (
					<AreaDetailsTable
						areaLists={selectedStore?.eboStoreAreaDetails}
						createDetailsApi={handleCreateDetails}
						selectedStore={selectedStore}
						setSelectedStore={setSelectedStore}
						handleUpdateDetails={handleUpdateDetails}
						handleFileUploadDetails={handleFileUploadDetails}
						handleGetStoreFiles={handleGetStoreFiles}
					/>
				)}
				{/* <BillerDetailsTable
					billerLists={selectedStore?.eboStoreBillerDetails}
					createDetailsApi={handleCreateDetails}
					selectedStore={selectedStore}
					setSelectedStore={setSelectedStore}
					handleUpdateDetails={handleUpdateDetails}
					handleFileUploadDetails={handleFileUploadDetails}
					handleGetStoreFiles={handleGetStoreFiles}
				/> */}

				{/* <DepositTable
					depositLists={selectedStore?.eboStoreDepositDetails}
					createDetailsApi={handleCreateDetails}
					selectedStore={selectedStore}
					setSelectedStore={setSelectedStore}
					handleUpdateDetails={handleUpdateDetails}
				/> */}

				{/* lease details */}
				{isEbo && (
					<LeaseAgreement
						agreementList={selectedStore?.eboStoreLeaseAgreementDetails}
						createDetailsApi={handleCreateDetails}
						selectedStore={selectedStore}
						setSelectedStore={setSelectedStore}
						handleUpdateDetails={handleUpdateDetails}
						handleFileUploadDetails={handleFileUploadDetails}
						handleGetStoreFiles={handleGetStoreFiles}
					/>
				)}
				{/* lease details */}
				{isEbo && (
					<Box display="flex" flexWrap="wrap" columnGap={"10px"} rowGap="10px" width="100%">
						<Box display="flex" flexWrap="wrap" width="100%" justifyContent="space-between">
							<Typography variant="button">(G5) Capex and Project Cost</Typography>
							<Box>
								<Button
									size="small"
									variant="contained"
									disabled={selectedStore.status === statusOptions.PUBLISHED.key}
									onClick={() => setCapexModal(true)}>
									Edit
								</Button>
							</Box>
							<Divider sx={{ width: "100%" }} />
						</Box>
						{storeCapexTableColumns
							?.filter((data) => data.data_type !== "FILE")
							?.map((row) => {
								return (
									<Stack className="vendor-input" spacing={0} width={"23%"}>
										<InputLabel>
											{row?.name}
											{/* {field?.required ? <span style={{ color: "red" }}>*</span> : ""} */}
										</InputLabel>
										<>
											<Typography variant="h6" fontSize={16}>
												{row?.data_type === datatypes.DATE.key
													? (selectedStore?.eboStoreCapexDetails?.length > 0 &&
															selectedStore?.eboStoreCapexDetails[0][row.key] &&
															moment(selectedStore?.eboStoreCapexDetails[0][row.key]).format(
																"DD-MM-YYYY"
															)) ||
													  "--"
													: selectedStore?.eboStoreCapexDetails?.length > 0
													? selectedStore?.eboStoreCapexDetails[0][row.key]
													: "--"}
											</Typography>
										</>
									</Stack>
								);
							})}
					</Box>
				)}
				{isEbo && capexModal && (
					<CapexTablePopup
						modal={capexModal}
						setModal={setCapexModal}
						type={
							selectedStore?.eboStoreCapexDetails.length > 0 ? addOrEditModelAction.UPDATE : addOrEditModelAction.CREATE
						}
						defaultData={selectedStore?.eboStoreCapexDetails.length > 0 ? selectedStore?.eboStoreCapexDetails[0] : {}}
						handleUpdateDetails={handleUpdateDetails}
						selectedStore={selectedStore}
						setSelectedStore={setSelectedStore}
						handleGetStoreFiles={handleGetStoreFiles}
						handleFileUploadDetails={handleFileUploadDetails}
						createDetailsApi={handleCreateDetails}
					/>
				)}
				{isEbo && (
					<StoreInsuranceTable
						insurenceLists={selectedStore?.eboStoreInsuranceDetails}
						createDetailsApi={handleCreateDetails}
						selectedStore={selectedStore}
						setSelectedStore={setSelectedStore}
						handleUpdateDetails={handleUpdateDetails}
						handleFileUploadDetails={handleFileUploadDetails}
						handleGetStoreFiles={handleGetStoreFiles}
					/>
				)}

				{isEbo && (
					<LiscenceTable
						liscenceLists={selectedStore?.eboStoreLiscenceDetails}
						createDetailsApi={handleCreateDetails}
						selectedStore={selectedStore}
						setSelectedStore={setSelectedStore}
						handleUpdateDetails={handleUpdateDetails}
						handleFileUploadDetails={handleFileUploadDetails}
						handleGetStoreFiles={handleGetStoreFiles}
					/>
				)}

				{/* <CamTable
					camLists={selectedStore?.eboStoreCamDetails}
					createDetailsApi={handleCreateDetails}
					selectedStore={selectedStore}
					setSelectedStore={setSelectedStore}
					handleUpdateDetails={handleUpdateDetails}
				/> */}

				{/* <RentRevenueTable
					rentRevenueLists={selectedStore?.eboStoreRentAndRevenueShareDetails}
					createDetailsApi={handleCreateDetails}
					selectedStore={selectedStore}
					setSelectedStore={setSelectedStore}
					handleUpdateDetails={handleUpdateDetails}
				/> */}
				{isEbo && (
					<RenovationTable
						renovationLists={selectedStore?.eboStoreRenovationDetails}
						createDetailsApi={handleCreateDetails}
						selectedStore={selectedStore}
						setSelectedStore={setSelectedStore}
						handleUpdateDetails={handleUpdateDetails}
					/>
				)}
				{!isEbo && (
					<MboLfsAgreement
						agreementList={selectedStore?.mbolfsAgreementDetails}
						createDetailsApi={handleCreateDetails}
						selectedStore={selectedStore}
						setSelectedStore={setSelectedStore}
						handleUpdateDetails={handleUpdateDetails}
						handleFileUploadDetails={handleFileUploadDetails}
						handleGetStoreFiles={handleGetStoreFiles}
					/>
				)}
				{!isEbo && (
					<MboLfsOtherDetails
						agreementList={selectedStore?.mbolfsOtherDetails}
						createDetailsApi={handleCreateDetails}
						selectedStore={selectedStore}
						setSelectedStore={setSelectedStore}
						handleUpdateDetails={handleUpdateDetails}
						handleFileUploadDetails={handleFileUploadDetails}
						handleGetStoreFiles={handleGetStoreFiles}
					/>
				)}
				<FileUploadTable
					filesDetails={filesDetails ?? []}
					setFilesDetails={setFilesDetails}
					handleFileUploadDetails={handleFileUploadDetails}
					createDetailsApi={handleCreateDetails}
					handleGetStoreFiles={handleGetStoreFiles}
					selectedStore={selectedStore}
				/>
			</Stack>
		);
	else
		return (
			<Stack display="flex" justifyContent="center" alignItems="center" height="100%" spacing={1}>
				<Typography variant="h5" p={2}>
					---- No Store Selected ----
				</Typography>
				<Button variant="contained" sx={{ textTransform: "capitalize" }} onClick={goToStoresPage}>
					Go to All Stores
				</Button>
			</Stack>
		);
};

export default BasicDetails;
