import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import { useOutletContext, useSearchParams } from "react-router-dom";
import { companyBasicDetailDummyValues, foreignKeys, supplierDrawerElements } from "data/supplierPageData";
import CompanyDetails from "./CompanyDetails/CompanyDetails";
import AllFactories from "./AllFactories/AllFactories";
import SupplierStatus from "./SupplierStatus/supplierStatus";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import FactoryDetails from "./FactoryDetails/FactoryDetails";
import Agreements from "./Agreemens/Agreements";
import { AuthState } from "context/AuthContext";

function SupplierUser() {
	const [tab, setTab] = useOutletContext();
	const [companyData, setCompanyData] = useState();
	const [factories, setFactories] = useState();
	const [selectedFactory, setSelectedFactory] = useState();
	const [isOnboarded, setIsOnboarded] = useState(false);
	const { setSnackBar, snackBar, setSnackBarOpen, setLoadingOpen } = GlobalState();
	const { user } = AuthState();

	const handleEditSApprovalDb = (type, pageData) => {
		let updatedData = [];
		let selectedApproval = "";
		companyData?.supplierApproval.forEach((data) => {
			if (data.type === type) {
				const updated = {
					...data,
					updated_by: user?.email,
					approved_date: null,
					remark: "",
				};
				updatedData.push(updated);
				selectedApproval = updated;
			} else {
				updatedData.push(data);
			}
		});
		updateSupplier(selectedApproval, updatedData, pageData, supplierCallback);
	};
	const handleEditFApprovalDb = (type, pageData) => {
		let updatedData = [];
		let selectedApproval = "";
		selectedFactory?.factoryApproval.forEach((data) => {
			if (data.type === type) {
				const updated = {
					...data,
					updated_by: "",
					approved_date: null,
					remark: "",
				};
				updatedData.push(updated);
				selectedApproval = updated;
			} else {
				updatedData.push(data);
			}
		});
		updateSupplier(selectedApproval, updatedData, pageData, factoryCallback);
	};
	const factoryCallback = (updatedData, pageData) => {
		setSelectedFactory({ ...selectedFactory, factoryApproval: updatedData });
		const updated = factories.filter((data) => data.id !== selectedFactory.id);
		setFactories([...updated, { ...selectedFactory, factoryApproval: updatedData }]);
	};

	const updateSupplier = async (apibody, updatedData, pageData, callbackFn) => {
		setLoadingOpen(true);
		await axios
			.put(`/api/supplier/approval_update/${apibody.id}`, apibody)
			.then((res) => {
				setSnackBarOpen(true);
				if (typeof callbackFn === "function") callbackFn(updatedData, pageData);
				setSnackBar({
					...snackBar,
					message: `Supplier updated`,
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			})
			.catch((err) => {
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: `Unable to update Supplier details `,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
		setLoadingOpen(false);
	};
	const supplierCallback = (updatedData, pageData) => {
		setCompanyData({ ...pageData, supplierApproval: updatedData });
	};
	const tabComponents = [
		{
			...supplierDrawerElements.company_info,
			component: (
				<CompanyDetails
					pageData={companyData}
					setPageData={setCompanyData}
					isOnboarded={isOnboarded}
					setIsOnboarded={setIsOnboarded}
					foreignKey={foreignKeys.supplier}
					disableEdit={false}
					handleEditSApprovalDb={(type, pageData) => handleEditSApprovalDb(type, pageData)}
				/>
			),
		},
		{
			...supplierDrawerElements.all_factories,
			component: (
				<AllFactories
					factories={factories}
					setFactories={setFactories}
					selectedFactory={selectedFactory}
					setSelectedFactory={setSelectedFactory}
					companyData={companyData}
					setTab={setTab}
				/>
			),
		},
		{
			...supplierDrawerElements.factory,
			component: (
				<FactoryDetails
					key={selectedFactory}
					foreignKey={foreignKeys.factory}
					pageData={selectedFactory}
					setPageData={setSelectedFactory}
					companyData={companyData}
					setTab={setTab}
					disableEdit={false}
					handleEditFApprovalDb={(type, pageData) => handleEditFApprovalDb(type, pageData)}
				/>
			),
		},
		{
			...supplierDrawerElements.agreements,
			component: (
				<Agreements
					key={selectedFactory}
					foreignKey={foreignKeys.factory}
					disableEdit={false}
					companyData={companyData}
					setCompanyData={setCompanyData}
				/>
			),
		},
		{
			...supplierDrawerElements.status,
			component: (
				<SupplierStatus
					foreignKey={foreignKeys.factory}
					allFactories={factories}
					setPageData={setSelectedFactory}
					companyData={companyData}
					setTab={setTab}
				/>
			),
		},
	];
	useEffect(() => {
		const fetch = async () => {
			setLoadingOpen(true);
			await axios
				.get("/api/supplier/find_or_create")
				.then((res) => {
					let data = res.data;
					if (data.created) {
						setCompanyData({ ...companyBasicDetailDummyValues, ...data.result });
						setFactories([]);
						setIsOnboarded(false);
					} else {
						setCompanyData(data.result);
						setFactories(data.result.supplierFactories);
						setIsOnboarded(data.result.is_onboarded);
					}
					// console.log(data.result);
				})

				.catch((err) => {
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "Unable to Load Supplier Data",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				});
			setLoadingOpen(false);
		};
		fetch();
	}, []);

	return (
		<Box sx={{ width: "100%" }}>
			{companyData &&
				tabComponents?.map((row) => (
					<Box width="95%" margin="auto" key={row.key} display={row.key === tab ? "block" : "none"}>
						{row.component}
					</Box>
				))}
			{/* <Modal
				sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
				open={modalOpen}
				onClose={handleModalClose}>
				<Box
					width="300px"
					display="flex"
					alignItems="center"
					justifyContent="center"
					sx={{ background: "white", borderRadius: 4, p: 4, height: "100px" }}>
					{setSuccessful ? (
						<Stack alignItems="center" spacing={2}>
							<Typography variant="h6">{message}</Typography>
							<Button variant="outlined" onClick={handleModalClose}>
								Close
							</Button>
						</Stack>
					) : (
						<CircularProgress />
					)}
				</Box>
			</Modal> */}
		</Box>
	);
}

export default SupplierUser;
