import React, { useState } from "react";
import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from "@mui/material";

function DiscountValueSection() {
	const [discounts, setDiscounts] = useState([
		{ buy: 5999, get: 1000 },
		{ buy: 7999, get: 1500 },
		{ buy: 12999, get: 2500 },
		{ buy: 17999, get: 4000 },
	]);
	return (
		<Box>
			{/* Discount Slab */}
			<Grid item xs={12}>
				<h4>Scheme Discount Slab</h4>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>BUY Value</TableCell>
								<TableCell>GET Value</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{discounts.map((row, index) => (
								<TableRow key={index}>
									<TableCell>{row.buy}</TableCell>
									<TableCell>{row.get}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Box>
	);
}

export default DiscountValueSection;
