import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import MasterGeneralInformation from "./MasterGeneralInformation/MasterGeneralInformation";
import ApplicableDate from "./ApplicableDateSection/ApplicableDate";
import DiscountValueSection from "./DiscountValueSection/DiscountValueSection";
import ProductSelectionSection from "./ProductSelectionSection/ProductSelectionSection";
import StoreAvailabilitySection from "./StoreAvailabilitySection/StoreAvailabilitySection";

function DiscountMaster() {
	const [value, setValue] = useState({});
	return (
		<Box display="flex" columnGap={"10px"} rowGap="10px" flexWrap="wrap" width="100%">
			<Stack direction={"row"}>
				<MasterGeneralInformation value={value} setValue={setValue} />
				<ApplicableDate value={value} setValue={setValue} />
			</Stack>
			<Stack direction={"row"} width={"100%"}>
				<StoreAvailabilitySection value={value} setValue={setValue} />
				<DiscountValueSection value={value} setValue={setValue} />
			</Stack>
			<Stack width={"100%"}>
				<ProductSelectionSection value={value} setValue={setValue} />
			</Stack>
		</Box>
	);
}

export default DiscountMaster;
