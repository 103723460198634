import { Dataset, Description } from "@mui/icons-material";
import { datatypes, inputTypes } from "data/globalData";

export const mboPaymentDashboardServices = {
	attribute_master: {
		name: "Attribute Master",
		key: "attribute_master",
		display: true,
		icon: <Dataset />,
		types: {
			attributes: {
				name: "Attributes",
				key: "attributes",
				display: true,
				icon: <Description />,
			},
			attribute_groups: {
				name: "Attribute Groups",
				key: "attribute_groups",
				display: true,
				icon: <Description />,
			},
		},
	},
	masters: {
		name: "Masters",
		key: "masters",
		display: true,
		icon: <Dataset />,
		types: {
			discount_master: {
				name: "Discount Master",
				key: "discount_master",
				display: true,
				icon: <Description />,
			},
		},
	},
};

// Scheme fields.
export const schemeTableColumns = [
	{
		name: "Scheme Name",
		key: "scheme_name",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
	{
		name: "Scheme Description",
		key: "scheme_description",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
	},
];

// store selection fields.
export const storeSelectionColumns = [
	{
		name: "Distributer / Partner",
		key: "distributer_partner",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: storeLiscenceTypes,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},
	{
		name: "State",
		key: "state",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: storeLiscenceTypes,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},
	{
		name: "Store Name",
		key: "store_name",
		input_type: inputTypes.MULTIPLE_SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		// option_data: {
		// 	type: arrType,
		// 	list: storeLiscenceTypes,
		// 	getOptionLabel: (row) => {
		// 		return { name: row.label, key: row.value };
		// 	},
		// },
	},
];
