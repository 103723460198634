import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	InputLabel,
	List,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { constraintsOptions, datatypes } from "data/globalData";
import { grey } from "@mui/material/colors";
import { pythonVmInstance } from "utils/AxiosInstances/RareboxPythonInstance";
import { GlobalState } from "context/GlobalContext";

const FileUpload = () => {
	const { setLoadingOpen, setSnackBarOpen, setSnackBar, snackBar } = GlobalState();
	const fileTypes = {
		GIT: {
			name: "GIT",
			key: "GIT",
			constraints: { NOTNULL: false, DISPLAY: true },
			data: {},
			data_type: datatypes.STRING.key,
			sort_no: 1,
		},
		TAT: {
			name: "TAT",
			key: "TAT",
			constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
			data: {},
			data_type: datatypes.STRING.key,
			sort_no: 1,
			group: "",
		},
		MIN_MAX: {
			name: "MIN MAX",
			key: "MIN_MAX",
			constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
			data: {},
			data_type: datatypes.STRING.key,
			sort_no: 1,
			group: "",
		},
		BUYING_SHEET: {
			name: "BUYING SHEET",
			key: "BUYING_SHEET",
			constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
			data: {},
			data_type: datatypes.STRING.key,
			sort_no: 1,
			group: "",
		},
	};
	const [files, setFiles] = useState({});
	const [errors, setErrors] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const handleClose = (event, reason) => {
		setErrors([]);
		setModalOpen(false);
	};
	const handleFileChange = (e, column) => {
		const newFile = e.target.files[0];
		if (newFile) {
			const newFileName = column.key + "-" + newFile?.name?.split(" ").join("_");
			const modifiedFile = new File([newFile], newFileName, { type: newFile.type });
			setFiles({ ...files, [column.key]: modifiedFile });
		}
	};

	const onUploadClick = () => {
		const form = new FormData();
		Object.keys(files).forEach((file, i) => {
			form.append(`files[]`, files[file]);
		});
		setLoadingOpen(true);
		pythonVmInstance
			.post("/replenishment/upload", form, { headers: { "Content-Type": "multipart/form-data" } })
			.then((res) => {
				// console.log(res?.data?.result);
				setErrors(res?.data);
				setLoadingOpen(false);

				setModalOpen(true);
				Object.keys(fileTypes)?.map((row) => {
					document.getElementById(fileTypes[row].key).value = "";
				});

				setFiles({});
			})
			.catch((err) => {
				setSnackBarOpen(true);
				if (err?.response?.data?.message) {
					setSnackBar({ ...snackBar, message: err?.response?.data?.message, severity: "error" });
				} else {
					setSnackBar({ ...snackBar, message: "Something went wrong", severity: "error" });
				}
				setLoadingOpen(false);
			});
	};
	return (
		<Stack maxWidth="1100px" spacing={3} margin="auto">
			<Box display="flex" justifyContent="space-between">
				<Typography variant="h4" width="100%">
					Upload Files
				</Typography>
			</Box>
			{/* table */}
			<Box display="flex" width="100%" columnGap="3%" flexWrap="wrap">
				{Object.keys(fileTypes)
					?.map((row) => fileTypes[row])
					.map((column) => {
						return (
							<Stack sx={{ width: "25%" }} spacing={"3px"} height={"70px"}>
								<InputLabel sx={{ color: grey[600] }}>
									{column?.name}
									{column?.constraints?.[constraintsOptions.NOTNULL.key] && <span style={{ color: "red" }}>*</span>}
								</InputLabel>
								<TextField
									id={column.key}
									variant={"outlined"}
									// sx={sx}
									size="small"
									type="file"
									// value={files && files[column.key] ? files[column.key] : ""}
									onChange={(e) => handleFileChange(e, column)}
									fullWidth
								/>
							</Stack>
						);
					})}
				<Box width="100%" pt={1}>
					<Button variant="contained" onClick={onUploadClick}>
						Upload
					</Button>
				</Box>
			</Box>
			<Dialog maxWidth="lg" fullWidth open={modalOpen}>
				<DialogTitle>FILE UPLOAD RESULTS</DialogTitle>
				<DialogContent>
					{errors.length
						? errors?.map((err) => (
								<List>
									<DialogContentText color={err?.error ? "red" : "green"}>
										{err.filename} : {err.status}
									</DialogContentText>
								</List>
						  ))
						: ""}
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} variant="outlined">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</Stack>
	);
};

export default FileUpload;
