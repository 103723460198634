import { constraintsOptions, datatypes, inputTypes, objType } from "data/globalData";
import CustomTextField from "components/EboStoreV4/CommonComponents/CustomTextfield/CustomTextfield";

import SelectInputField from "components/EboStoreV4/CommonComponents/SelectInputField/SelectInputField";

import moment from "moment";

// Loop through dependencyColData to update values
const updateDependentCols = async (column, dependencyColData, targetValue, options, data) => {
	// console.log("column", column);
	// console.log("dependencyColData", dependencyColData);
	// console.log(" targetValue", targetValue);
	// console.log("options", options);
	const updatePromises = Object.keys(dependencyColData).map(async (element) => {
		console.log(element, column.key);
		if (column.key === element) {
			// console.log("element-9873498372984732897498327498327498137984", element);
			try {
				const value = await dependencyColData[element].val(
					element,
					targetValue,
					dependencyColData[element].col,
					options,
					data
				);
				console.log("dependencyColDatadependencyColData", value);

				// Return an object with the column name and value
				return { [dependencyColData[element].col]: value };
			} catch (error) {
				console.error(`Error updating column for ${element}:`, error);
				return null; // Return null if there’s an error
			}
		}
		// return null; // Return null if key doesn't match
	});

	// Await all promises to resolve
	const results = await Promise.all(updatePromises);

	// Combine non-null results into a single object
	const obj = results.reduce((acc, result) => {
		if (result) {
			return { ...acc, ...result };
		}
		return acc;
	}, {});

	// console.log("Final object:", obj, results);
	return obj;
};

// Example usage
// updateDependentColumns(column.key, e.target.value);

const RenderInputField = ({ column, setData, data, width, selectOptObjName, dependencyColData, disabled }) => {
	width = column?.data_type === datatypes.LONGTEXT.key ? "74%" : width ? width : "24%";
	if (column?.data_type === datatypes.DATE.key) {
		data = { ...data, [column.key]: moment(data[column.key]).format("YYYY-MM-DD") };
	}
	const options = column[selectOptObjName] ? column[selectOptObjName] : { type: objType, list: {} };
	const handleChange = async (e) => {
		let obj = { ...data };
		obj[column.key] = e.target.value;
		// console.log(typeof dependencyColData, "dependencyColDatadependencyColDatadependencyColData");
		// setData(obj);
		if (typeof dependencyColData === "object") {
			const changedKeys = await updateDependentCols(column, dependencyColData, e.target.value, options, data);
			let updatedData = typeof changedKeys === "object" ? { ...obj, ...changedKeys } : obj;
			setData({ ...updatedData });
		} else setData(obj);
		// console.log(e, e.target.value, dependencyColData, obj, "dependencyColDatadependencyColDatadependencyColData");
	};

	if (column)
		if (column.input_type === inputTypes.ENTER.key || column.input_type === inputTypes.AUTO_GENERATE.key) {
			return (
				column?.constraints[constraintsOptions.DISPLAY.key] && (
					<CustomTextField
						variant={"outlined"}
						data={data}
						disabled={column?.input_type === inputTypes.AUTO_GENERATE.key || disabled}
						setData={setData}
						onChange={handleChange}
						column={column}
						width={column?.data_type?.includes("long") ? "70%" : width}
						required={column?.constraints[constraintsOptions.NOTNULL.key] ? true : false}
						type={column?.data_type}
					/>
				)
			);
		} else {
			return (
				column?.constraints[constraintsOptions.DISPLAY.key] && (
					<SelectInputField
						// sx={{ width: "55%", p: 1, background: grey[200], fontWeight: 400, overflow: "hidden" }}
						data={data}
						setData={setData}
						column={column}
						width={width}
						onChange={handleChange}
						options={options}
						required={column?.constraints[constraintsOptions.NOTNULL.key] ? true : false}
						disabled={disabled}
					/>
				)
			);
		}
};

export default RenderInputField;
