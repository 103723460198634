import { MoreVert } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

const DatagridCustomActions = ({ row }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton onClick={handleClick}>
				<MoreVert />
			</IconButton>
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				<MenuItem onClick={() => alert(`Editing ${row.name}`)}>Duplicate Profile</MenuItem>
				<MenuItem onClick={() => alert(`Deleting ${row.name}`)}>Delete</MenuItem>
			</Menu>
		</>
	);
};

export default DatagridCustomActions;
