import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { colors } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import { objType } from "data/globalData";
import { inputTypes } from "data/globalData";

const SelectInputField = ({ data, setData, column, width, options, sx, onChange, disabled, required }) => {
	const sxData = sx ? sx : {};
	let { type, list, getOptionLabel } = options;
	let onChangeFun = typeof onChange === "function" ? onChange : (e) => setData({ ...data, [column.key]: e.target.value });
	if (type === objType) list = Object.keys(list)?.map((key) => list[key]);
	const multile = column.input_type === inputTypes.MULTIPLE_SELECT.key ? true : false;

	return (
		<Stack sx={{ width }} spacing={"3px"} height={"70px"}>
			<InputLabel sx={{ overflow: "hidden", color: grey[600] }}>
				{column?.name}
				{required && <span style={{ color: "red" }}>*</span>}
			</InputLabel>
			<Select
				size="small"
				fullWidth
				multiple={multile}
				variant="outlined"
				required={required}
				placeholder={`Enter ${column?.name}`}
				MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
				value={data && data[column.key] ? data[column.key] : multile ? [] : inputTypes.SELECT.key}
				disabled={disabled}
				onChange={onChangeFun}>
				<MenuItem value={inputTypes.SELECT.key}>
					<span style={{ color: grey[400] }}>Select</span>
				</MenuItem>
				{list?.map((row) => {
					if (typeof getOptionLabel === "function") row = getOptionLabel(row);
					return (
						<MenuItem key={row?.key} value={row?.key}>
							{row?.name}
						</MenuItem>
					);
				})}
			</Select>
		</Stack>
	);
};

export default SelectInputField;
