import {
	Box,
	Button,
	CircularProgress,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { GlobalState } from "context/GlobalContext";
import moment from "moment";
import ListItemButton from "@mui/material/ListItemButton";
import CodeIcon from "@mui/icons-material/Code";
import React, { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import FileSaver from "file-saver";
import { pythonVmInstance } from "utils/AxiosInstances/RareboxPythonInstance";

const isStartDateGraterThanEndDate = (startDateText, endDateText) => {
	const start_date = dayjs(startDateText);
	const end_date = dayjs(endDateText);

	const startday = start_date.get("D");
	const startYear = start_date.get("year");
	const startMonth = start_date.get("M");

	const endDay = end_date.get("D");
	const endYear = end_date.get("year");
	const endMonth = end_date.get("M");

	if (startYear > endYear) return true;
	if (startYear === endYear && startMonth > endMonth) return true;
	if (startYear === endYear && startMonth === endMonth && startday > endDay) {
		return true;
	}
	// returns true if start date >end date
	return false;
};

// planning sale query component.
const PlanningSaleQuery = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const [loading, setLoading] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [department, setDepartment] = useState("");
	const [division, setDivision] = useState("SELECT");
	const [brand, setBrand] = useState("SELECT");
	const [subCategory, setSubCategory] = useState("");

	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		setLoading(true);
		if ((startDate === "" && endDate !== "") || (startDate !== "" && endDate === "")) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "please enter both startdate and end date",
				severity: "warning",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoading(false);

			return;
		}
		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "start date should be less than end date",
				severity: "warning",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoading(false);

			return;
		}
		try {
			await axios
				.get(
					`/api/custom_queries/planning-sale-query?start_date=${startDate}&end_date=${endDate}&department=${department}&division=${division}&brand=${brand}&subcategory=${subCategory}`
				)
				.then((res) => {
					const updatedData = res.data;
					if (res.status === 200) {
						setLoading(false);
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message: "file saved successfully in data hub",
							severity: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
					} else if (res.status === 204) {
						setLoading(false);
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message: res.statusText,
							severity: "error",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
					}
				});
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "failed to run query",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoading(false);
		}
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6">Planning sale query</Typography>
			{/* form */}
			<Box display="flex" columnGap={2} flexWrap={"wrap"} justifyContent={""} rowGap={2}>
				<Stack spacing={1}>
					<InputLabel>Select Start Date:</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						sx={{ width: "200px" }}
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Select End Date:</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						sx={{ width: "200px" }}
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Enter Department</InputLabel>
					<TextField
						size="small"
						type="text"
						value={department}
						placeholder="Enter Department"
						onChange={(e) => setDepartment(e.target.value.toUpperCase())}
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Enter Subcategory</InputLabel>
					<TextField
						size="small"
						type="text"
						value={subCategory}
						placeholder="Enter Subcategory"
						onChange={(e) => setSubCategory(e.target.value.toUpperCase())}
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Division</InputLabel>
					<Select value={division} onChange={(e) => setDivision(e.target.value)} sx={{ width: "200px" }} size="small">
						<MenuItem value={"SELECT"}>SELECT</MenuItem>
						<MenuItem value={"APPARELS"}>APPARELS</MenuItem>
						<MenuItem value={"FOOT WEAR"}>FOOT WEAR</MenuItem>
						<MenuItem value={"ADDONS"}>ADDONS</MenuItem>
					</Select>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Brand</InputLabel>
					<Select value={brand} onChange={(e) => setBrand(e.target.value)} sx={{ width: "200px" }} size="small">
						<MenuItem value={"SELECT"}>SELECT</MenuItem>
						<MenuItem value={"RARE RABBIT"}>RARE RABBIT</MenuItem>
						<MenuItem value={"RAREISM"}>RAREISM</MenuItem>
					</Select>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				disabled={loading}
				startIcon={loading ? <CircularProgress color="inherit" size="20px" /> : <CodeIcon />}>
				{loading ? "Loading..." : "Run"}
			</Button>
		</Stack>
	);
};

// EBO Dispatch query component.
const EboDispatch = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const [loading, setLoading] = useState(false);
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [brand, setBrand] = useState("SELECT");

	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (isStartDateGraterThanEndDate(startDate, endDate)) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "start date should be less than end date",
				severity: "warning",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoading(false);

			return;
		}
		try {
			await axios
				.get(`/api/custom_queries/ebo-dispatch?start_date=${startDate}&end_date=${endDate}&brand=${brand}`)
				.then((res) => {
					const updatedData = res.data;
					if (res.status === 200) {
						setLoading(false);
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message: "file saved successfully in data hub",
							severity: "success",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
					} else if (res.status === 204) {
						setLoading(false);
						setSnackBarOpen(true);
						setSnackBar({
							...snackBar,
							message: res.statusText,
							severity: "error",
							anchorOrigin: { horizontal: "center", vertical: "top" },
						});
					}
				});
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "failed to run query",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoading(false);
		}
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6">EBO Dispatch</Typography>
			{/* form */}
			<Box display="flex" columnGap={2} flexWrap={"wrap"} justifyContent={""} rowGap={2}>
				<Stack spacing={1}>
					<InputLabel>Select Start Date:</InputLabel>
					<TextField
						size="small"
						type="date"
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						sx={{ width: "200px" }}
					/>
				</Stack>
				<Stack spacing={1}>
					<InputLabel>Select End Date:</InputLabel>
					<TextField
						size="small"
						type="date"
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						sx={{ width: "200px" }}
					/>
				</Stack>

				<Stack spacing={1}>
					<InputLabel>Brand</InputLabel>
					<Select
						value={brand}
						onChange={(e) => setBrand(e.target.value.toUpperCase())}
						sx={{ width: "200px" }}
						size="small">
						<MenuItem value={"SELECT"}>SELECT</MenuItem>
						<MenuItem value={"RARE RABBIT"}>RARE RABBIT</MenuItem>
						<MenuItem value={"RAREISM"}>RAREISM</MenuItem>
					</Select>
				</Stack>
			</Box>
			<Button
				variant="contained"
				sx={{ width: "200px" }}
				type="submit"
				disabled={loading}
				startIcon={loading ? <CircularProgress color="inherit" size="20px" /> : <CodeIcon />}>
				{loading ? "Loading..." : "Run"}
			</Button>
		</Stack>
	);
};

// EBO SOH daily data  component.
const EboSohDailyData = () => {
	const { setSnackBar, snackBar, setSnackBarOpen } = GlobalState();
	const [loading, setLoading] = useState(false);

	const [brand, setBrand] = useState("SELECT");

	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			await axios.get(`/api/custom_queries/ebo-soh-daily-data?brand=${brand}`).then((res) => {
				const updatedData = res.data;
				if (res.status === 200) {
					setLoading(false);
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "file saved successfully in data hub",
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				} else if (res.status === 204) {
					setLoading(false);
					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: res.statusText,
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				}
			});
		} catch (error) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "failed to run query",
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoading(false);
		}
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6">EBO SOH Daily Data</Typography>
			{/* form */}
			<Box display="flex" columnGap={2} flexWrap={"wrap"} justifyContent={""} rowGap={2}>
				<Stack spacing={1}>
					<InputLabel>Brand</InputLabel>
					<Select
						value={brand}
						onChange={(e) => setBrand(e.target.value.toUpperCase())}
						sx={{ width: "200px" }}
						size="small">
						<MenuItem value={"SELECT"}>SELECT</MenuItem>
						<MenuItem value={"RARE RABBIT"}>RARE RABBIT</MenuItem>
						<MenuItem value={"RAREISM"}>RAREISM</MenuItem>
					</Select>
				</Stack>
			</Box>
			<Button
				variant="contained"
				disabled={loading}
				sx={{ width: "200px" }}
				type="submit"
				startIcon={loading ? <CircularProgress color="inherit" size="20px" /> : <CodeIcon />}>
				{loading ? "Loading..." : "Run"}
			</Button>
		</Stack>
	);
};

const InventorySnapshot = () => {
	const { setSnackBar, snackBar, setSnackBarOpen, setLoadingOpen } = GlobalState();
	const [loading, setLoading] = useState(false);
	const [sync, setSync] = useState();

	const fetchSync = async () => {
		try {
			await axios.get(`/api/custom_queries/fetch_last_sync`).then((res) => {
				if (res.status === 200) {
					setSync(res?.data[0]?.api_pull_last_updated);
					setLoading(false);
				}
			});
		} catch (error) {
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchSync();
	}, []);

	const handleRunQueryClick = async (e) => {
		e.preventDefault();
		setLoadingOpen(true);
		await pythonVmInstance({
			url: `/wh-soh/download`,
			headers: {
				"Content-Type": "application/json",
			},
			method: "GET",
			responseType: "blob",
		})
			.then((res) => {
				const url = URL.createObjectURL(res.data);
				FileSaver.saveAs(url, `inventory_snapshot_${new Date().toISOString().split("T")[0]}.csv`);
				setLoadingOpen(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: "Downloaded the file",
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			})
			.catch((err) => {
				console.log(err);
				setSnackBarOpen(true);
				setLoadingOpen(false);
				setSnackBar({
					...snackBar,
					message: "Unable to Download the file",
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			});
	};

	return (
		<Stack spacing={2} component="form" onSubmit={handleRunQueryClick}>
			<Typography variant="h6">Inventory Snapshot Download</Typography>
			<Typography variant="subtitle1">Last synced with UC: {sync}</Typography>

			<Button
				variant="contained"
				disabled={loading}
				sx={{ width: "200px" }}
				type="submit"
				startIcon={loading ? <CircularProgress color="inherit" size="20px" /> : ""}>
				{loading ? "Loading..." : "Download"}
			</Button>
		</Stack>
	);
};

const Queries = ({ history, setHistory }) => {
	const [selected, setSelected] = useState(0);

	const queryTypes = [
		{
			key: 0,
			name: "Planning sale query",
			component: <PlanningSaleQuery history={history} setHistory={setHistory} />,
		},
		{
			key: 1,
			name: "EBO dispatch",
			component: <EboDispatch history={history} setHistory={setHistory} />,
		},
		{
			key: 2,
			name: "EBO SOH daily data",
			component: <EboSohDailyData history={history} setHistory={setHistory} />,
		},
		{
			key: 3,
			name: "Inventory Snapshot",
			component: <InventorySnapshot history={history} setHistory={setHistory} />,
		},
	];

	const handleQuerySelect = (key) => {
		setSelected(key);
	};
	return (
		<Box display="flex" height="100%">
			<Box height="100%" width={170} sx={{ borderRight: 1, borderColor: "divider" }}>
				{queryTypes &&
					queryTypes.map((row) => {
						return (
							<ListItemButton
								onClick={() => handleQuerySelect(row.key)}
								sx={{ padding: "2px", margin: "5px", borderRadius: "4px" }}
								selected={row.key === selected}
								key={row.key}>
								<ListItemText sx={{ padding: "2px 15px", fontSize: "13px" }} primary={row.name} />
							</ListItemButton>
						);
					})}
			</Box>

			{queryTypes &&
				queryTypes.map((row) => {
					return (
						<Box width="80%" height="100%" p={2} key={row.key} display={row.key === selected ? "block" : "none"}>
							{row.component}
						</Box>
					);
				})}
		</Box>
	);
};

export default Queries;
