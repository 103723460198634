import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import axios from "axios";
import { debounce, set } from "lodash";
import parseErrMsgFromError from "utils/errorFunctions/parseErrorMessageFromError";
import { GlobalState } from "context/GlobalContext";

const SearchBarWithDebounce = ({ selectedProfile, setSelectedProfile }) => {
	const { setSnackBar, setSnackBarOpen, snackBar } = GlobalState();
	const [query, setQuery] = useState("");
	const [loading, setLoading] = useState(false);
	const [defaultProfileLoad, setDefaultProfileLoad] = useState();
	const [suggestions, setSuggestions] = useState([]);
	const fetchLogicProfiles = async (offset, recordsLimit) => {
		setLoading(true);
		try {
			const res = await axios.get(`/api/replenishment/get_logic_profiles?limit=${recordsLimit}&offset=${offset}`);

			res && setLoading(false);

			if (res?.data?.result && Array.isArray(res.data.result)) {
				setDefaultProfileLoad(res.data.result);
				setSuggestions(res.data.result);
			} else {
				setDefaultProfileLoad([]); // Fallback to an empty array
				setSuggestions([]);
			}
		} catch (err) {
			console.log(err);
			setSnackBar({ ...snackBar, message: parseErrMsgFromError(err), severity: "error" });
			setSnackBarOpen(true);
			setLoading(false);
		}
	};
	useEffect(() => {
		fetchLogicProfiles(0, 20);
	}, []);

	const fetchSuggestions = async (searchTerm) => {
		if (!searchTerm.trim()) return; // Avoid empty queries
		setLoading(true);
		try {
			const response = await axios.get(`/api/replenishment/search_logic_profiles?search=${searchTerm}`);
			setSuggestions(response?.data?.result);
		} catch (error) {
			console.error("Error fetching suggestions:", error);
		} finally {
			setLoading(false);
		}
	};

	// Debounce API calls (wait 1 sec after typing)
	const debouncedFetch = useMemo(
		() =>
			debounce((searchTerm) => {
				fetchSuggestions(searchTerm);
			}, 1000), // 1-second delay
		[]
	);

	useEffect(() => {
		if (!query) {
			setSuggestions(defaultProfileLoad);
		} else {
			debouncedFetch(query);
		}
	}, [query, debouncedFetch]);
	// 🔹 Cleanup debounce on unmount
	useEffect(() => {
		return () => debouncedFetch.cancel();
	}, [debouncedFetch]);

	const handleChange = (event, newValue) => {
		setSelectedProfile(newValue);
		console.log("new_value", newValue);
	};
	return (
		<Autocomplete
			freeSolo
			options={suggestions ?? []}
			loading={loading}
			value={selectedProfile}
			onChange={handleChange}
			onInputChange={(event, newInputValue) => setQuery(newInputValue)}
			getOptionLabel={(option) => option.profile_name || ""}
			renderOption={(props, option) => (
				<li {...props} key={option.id} value={option.id}>
					<strong>{option.profile_name}</strong>
					{/* - <span style={{ color: "gray" }}>{option?.brand}</span> */}
				</li>
			)}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Select Profile"
					variant="outlined"
					size="small"
					fullWidth
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<>
								{loading ? <CircularProgress size={20} /> : null}
								{params.InputProps.endAdornment}
							</>
						),
					}}
				/>
			)}
		/>
	);
};

export default SearchBarWithDebounce;
