import React, { useState } from "react";
import { Grid, TextField, Checkbox, FormControlLabel, Box, Typography, Divider, Container } from "@mui/material";
import { date_availability_fields } from "../data";
import RenderInputField from "components/MboPaymentWorking/CommonComponents/RenderInputFiled/RenderInputFiled";

function ApplicableDate({ value, setValue }) {
	// State variables
	const [scheme, setScheme] = useState("");
	const [brand, setBrand] = useState("ABC Fashion");
	const [accounting, setAccounting] = useState("PROMO");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [days, setDays] = useState({});
	const [discounts, setDiscounts] = useState([
		{ buy: 5999, get: 1000 },
		{ buy: 7999, get: 1500 },
		{ buy: 12999, get: 2500 },
		{ buy: 17999, get: 4000 },
	]);

	return (
		<Box p={3} width="50%">
			<Divider>
				<Typography variant="h6">Applicable Dates</Typography>
			</Divider>
			<Box display="flex" columnGap={1} rowGap="10px" flexWrap="wrap" width={"100%"} justifyContent={"space-around"}>
				{date_availability_fields?.map((row) => {
					return (
						<RenderInputField
							column={row}
							data={value}
							setData={setValue}
							width="32%"
							selectOptObjName={"option_data"}
							// dependencyColData={autoUpdatestoreMainFieldsData}
						/>
					);
				})}
			</Box>
			{/* Applicable Days */}
			<Grid item xs={12} pl={6} pt={2}>
				<Typography variant="subtitle2">Applicable Days</Typography>
				<Grid container spacing={0}>
					{["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day, index) => (
						<Grid item xs={4} key={day}>
							<FormControlLabel
								control={<Checkbox checked={!!days[day]} onChange={() => setDays({ ...days, [day]: !days[day] })} />}
								label={day}
							/>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Box>
	);
}

export default ApplicableDate;
