import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Card, CardContent, Typography, Box, Tooltip } from "@mui/material";
import { DragIndicator } from "@mui/icons-material";
import { grey } from "@mui/material/colors";
import { muiCustomScrollbar } from "data/globalData";
import { logicProfileStoreGroupingAssoc, storeGroupingtypes, storeMastShortNameKey, storeMastStoreCodeKey } from "../../data";

const initialStores = ["Store A", "Store B", "Store C", "Store D", "Store E"];

export const DragDropStores = ({ storeCodeList, storeFullDataList, profileData, setProfileData }) => {
	let storeFullDataListObj = storeFullDataList?.reduce((acc, curr) => {
		acc[curr?.[storeMastStoreCodeKey]] = curr;
		return acc;
	}, {});

	// useEffect(() => {
	// 	if (profileData?.[logicProfileStoreGroupingAssoc]?.[storeGroupingtypes.to_allocate.key]) {
	// 	} else
	// 		setProfileData((prev) => {
	// 			return {
	// 				...prev,
	// 				[logicProfileStoreGroupingAssoc]: {
	// 					...prev?.[logicProfileStoreGroupingAssoc],
	// 					[storeGroupingtypes.to_allocate.key]: storeCodeList ? storeCodeList : initialStores,
	// 				},
	// 			};
	// 		});
	// }, [profileData]);

	const setProfileDataAfterDragging = (data, column) => {
		setProfileData((prev) => {
			return {
				...prev,
				[logicProfileStoreGroupingAssoc]: { ...prev?.[logicProfileStoreGroupingAssoc], [column]: data },
			};
		});
	};

	const handleDragEnd = (event, info, store, source, setSource, target, setTarget) => {
		if (!target) return;
		setSource(source.filter((s) => s !== store));
		setTarget([...target, store]);
	};

	return (
		<Box display="flex" justifyContent="center" gap={2} p={1}>
			{Object.keys(storeGroupingtypes)
				?.map((row) => storeGroupingtypes[row])
				.map((row) => {
					return {
						...row,
						list: profileData?.[logicProfileStoreGroupingAssoc]?.[row?.key] ?? [],
						setList: (data) => setProfileDataAfterDragging(data, row?.key),
					};
				})
				?.map(({ name, key, list, setList, ...rest }, index) => (
					<Card key={index} sx={{ width: 250, p: 2, background: grey[50], boxShadow: 1 }}>
						{console.log(list, "djsfhydsgfhdfsdj")}
						<Typography variant="h6" fontWeight="bold" mb={2}>
							{name}
						</Typography>
						<Box
							sx={{ height: 250, overflowY: "scroll", ...muiCustomScrollbar }}
							onDragOver={(e) => e.preventDefault()}
							onDrop={(e) => {
								const store = e.dataTransfer.getData("store");
								const source = e.dataTransfer.getData("source");
								if (source === key) return;
								handleDragEnd(
									e,
									null,
									store,
									profileData?.[logicProfileStoreGroupingAssoc]?.[source] ?? [],
									(data) => setProfileDataAfterDragging(data, source),
									list,
									setList
								);
							}}>
							{list?.map((store) => (
								<motion.div
									key={store}
									style={{
										display: "flex",
										alignItems: "center",
										padding: 10,
										marginBottom: 8,
										backgroundColor: "white",
										borderRadius: 4,
										boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.07)",
										cursor: "pointer",
									}}
									draggable
									onDragStart={(e) => {
										e.dataTransfer.setData("store", store);
										e.dataTransfer.setData("source", key);
									}}>
									<DragIndicator style={{ marginRight: 8, color: "gray" }} />
									<Tooltip
										title={`${storeFullDataListObj[store]?.[storeMastShortNameKey]}(${store})` ?? ""}
										placement="bottom-start">
										<Typography noWrap>{storeFullDataListObj?.[store]?.[storeMastShortNameKey]}</Typography>
									</Tooltip>
								</motion.div>
							))}
						</Box>
					</Card>
				))}
		</Box>
	);
};
export default DragDropStores;
