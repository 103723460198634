import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RareBoxLogo from "../Logos/RareBoxLogo";
import { GlobalState } from "./GlobalContext";
import axios from "axios";
import { userTypes } from "data/authData";
import { CustomLinearProgress } from "components/ReusableComponents/CustomLinearProgress/CustomLinearProgress";

const AuthContext = createContext();
// api url

const AuthProvider = ({ children }) => {
	const [user, setUser] = useState();
	const [accessMaster, setAccessMaster] = useState();
	const [loadingUser, setLoadingUser] = useState(false);
	const { loginUser, rootPath, setRootPath, setLoginUser } = GlobalState();

	const navigate = useNavigate();

	const setRootPathFun = (user) => {
		let path;
		if (user && user.type === userTypes.external) {
			path = `/external/${user.other_data.root_path}`;
			navigate(path);
		} else path = `/explore`;
		setRootPath(path);
	};

	const loadUser = async () => {
		try {
			await axios
				.get(
					"/api/auth/load_user"
					//  {
					// 	headers: {
					// 		Authorization: `Bearer ${token}`,
					// 	},
					// }
				)
				.then((res) => {
					let user = res.data.user;
					setUser(user);
					setLoginUser(user);
					setRootPathFun(user);
					setLoadingUser(false);
				});
		} catch (error) {
			console.log("load user failed");
			setLoadingUser(false);
			setUser();
			localStorage.removeItem("user_token");
			localStorage.removeItem("py_token");
			navigate("/");
		}
	};

	useEffect(() => {
		const token = localStorage.getItem("user_token");
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		if (loginUser && !user) {
			setUser(loginUser);
			setRootPathFun(loginUser);
		} else if (!loginUser && !user) {
			if (token !== undefined) {
				setLoadingUser(true);
				loadUser();
			} else {
				setLoadingUser(false);
				navigate("/");
			}
		}
	}, [user]);

	return (
		<AuthContext.Provider
			value={{
				user,
				setUser,
				loadingUser,
			}}>
			{loadingUser && !user ? (
				<div
					style={{
						width: "200px",
						position: "absolute",
						left: "50%",
						top: "50%",
						transform: "translate(-50%,-50%)",
					}}>
					<RareBoxLogo />
					<CustomLinearProgress />
				</div>
			) : (
				children
			)}
		</AuthContext.Provider>
	);
};

export const AuthState = () => {
	return useContext(AuthContext);
};

export default AuthProvider;
