import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import { useState } from "react";
import axios from "axios";
import { GlobalState } from "context/GlobalContext";
import { useNavigate, useParams } from "react-router-dom";

const ForgotPasswordReset = () => {
	const { setSnackBar, snackBar, setSnackBarOpen, Loadingopen, setLoadingOpen } = GlobalState();

	const navigate = useNavigate();
	const { token } = useParams();

	const [confirmPassword, setConfirmPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const formStack = {
		width: "80%",
	};

	const onFormSubmit = async (e) => {
		e.preventDefault();
		// setLoadingOpen(true);
		if (newPassword !== confirmPassword) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message: "Password mismatch",
				severity: "warning",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);
			return;
		}
		// if (newPassword.length < 5) {
		// 	setSnackBarOpen(true);
		// 	setSnackBar({
		// 		...snackBar,
		// 		message: "Password length should be more that five charecters",
		// 		severity: "warning",
		// 		anchorOrigin: { horizontal: "center", vertical: "top" },
		// 	});
		// 	setLoadingOpen(false);
		// 	return;
		// }
		const search = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
		let regex = new RegExp(search, "i");
		if (!regex.test(confirmPassword) | !regex.test(newPassword)) {
			setSnackBarOpen(true);
			setSnackBar({
				...snackBar,
				message:
					"Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.",
				severity: "error",
			});
			return;
		}
		setLoadingOpen(true);
		try {
			await axios.patch(`/api/auth/reset_forgot_password/${token}`, { newPassword, confirmPassword }).then((res) => {
				let data = res.data;
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: data.error,
					severity: "success",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
				if (data.status === "success") {
					setLoadingOpen(false);
					navigate(`/explore`);
				} else {
					setSnackBar({
						...snackBar,
						message: data.error,
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				}
				setLoadingOpen(false);
			});
		} catch (error) {
			setSnackBarOpen(true);
			let message = "Internal Server Error";
			if (error.response) message = error?.response?.data?.error;
			setSnackBar({
				...snackBar,
				message,
				severity: "error",
				anchorOrigin: { horizontal: "center", vertical: "top" },
			});
			setLoadingOpen(false);
		}
	};

	return (
		<Box
			width="100%"
			height="100%"
			display="flex"
			alignItems="center"
			justifyContent="center"
			component="form"
			mt={"15vh"}
			onSubmit={onFormSubmit}>
			<Stack width="30%" height="30%" alignItems="center" spacing={2} pt={4}>
				<Typography textAlign="center" variant="h5">
					Reset Password
				</Typography>
				<Stack sx={formStack} spacing={1}>
					<InputLabel>New Password:</InputLabel>
					<TextField
						size="small"
						type="password"
						value={newPassword}
						onChange={(e) => setNewPassword(e.target.value)}
						placeholder="Enter new Password"
						required
					/>
				</Stack>
				<Stack sx={formStack} spacing={1}>
					<InputLabel>Confirm New Password:</InputLabel>
					<TextField
						size="small"
						type="password"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						placeholder="Confirm New Password"
						required
					/>
				</Stack>
				<Box alignSelf="center">
					<Button variant="contained" type="submit" sx={{ width: 150 }}>
						Submit
					</Button>
				</Box>
			</Stack>
		</Box>
	);
};

export default ForgotPasswordReset;
