import React, { useState } from "react";
import axios from "axios";
import { Add, Close } from "@mui/icons-material";
import { Box, Button, Fab } from "@mui/material";
import styled from "@emotion/styled";
import { AuthState } from "context/AuthContext";
import { custom } from "data/authData";
import { GlobalState } from "context/GlobalContext";

function FloatingButton({ showMenu, handleMenuToggle, path, open, setOpen, fetchContents }) {
	const [status, setStatus] = useState("");
	const { user } = AuthState();
	console.log("sdfghjk", user);
	const disabled = user.role?.[custom.FIN_VIEWER.key] === true;
	const { setSnackBar, snackBar, setSnackBarOpen, loginUser, setLoadingOpen } = GlobalState();

	const VisuallyHiddenInput = styled("input")({
		clip: "rect(0 0 0 0)",
		clipPath: "inset(50%)",
		height: 1,
		overflow: "hidden",
		position: "absolute",
		bottom: 0,
		left: 0,
		whiteSpace: "nowrap",
		width: 1,
	});

	// Handle file upload logic
	const handleUpload = async (event) => {
		setLoadingOpen(true);
		const files = event.target.files;
		if (files && files.length > 0) {
			const uploadedFile = files[0]; // Assuming only one file is uploaded
			// Create FormData to send file to the server
			const formData = new FormData();
			Array.from(files).forEach((file) => {
				formData.append("files", file); // Backend should handle multiple files as "files"
			});
			// formData.append("file", uploadedFile);
			formData.append("filePath", path.join("/"));

			// setStatus("Uploading file...");

			try {
				// Send the file to the backend
				const response = await axios.post("api/finance_workspace/upload_files", formData, {
					headers: {
						"Content-Type": "multipart/form-data", // Make sure to set this for file uploads
					},
				});

				if (response.status === 200) {
					setLoadingOpen(false);

					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "File uploaded successfully",
						severity: "success",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
					fetchContents(path);
				} else {
					setLoadingOpen(false);

					setSnackBarOpen(true);
					setSnackBar({
						...snackBar,
						message: "Failed to upload",
						severity: "error",
						anchorOrigin: { horizontal: "center", vertical: "top" },
					});
				}
			} catch (error) {
				setLoadingOpen(false);
				setSnackBarOpen(true);
				setSnackBar({
					...snackBar,
					message: `Something went wrong`,
					severity: "error",
					anchorOrigin: { horizontal: "center", vertical: "top" },
				});
			}
		}
	};
	return (
		<Box>
			{/* Floating Action Button */}
			<Fab
				disabled={disabled}
				color="primary"
				aria-label="Upload"
				sx={{
					position: "fixed",
					bottom: 16,
					right: 16,
					transition: "transform 0.1s ease-in-out",
					transform: showMenu ? "rotate(45deg)" : "rotate(0deg)",
				}}
				onClick={handleMenuToggle}>
				<Add />
			</Fab>

			{showMenu && (
				<Box
					sx={{
						position: "fixed",
						bottom: 80,
						right: 16,
						display: "flex",
						flexDirection: "column",
						gap: 1,
						zIndex: 1000,
					}}>
					{/* Create Folder Button */}
					<Box
						onClick={() => {
							setOpen(!open);
							handleMenuToggle();
						}}
						sx={{
							backgroundColor: "#000",
							color: "#fff",
							padding: "8px 16px",
							borderRadius: "999px",
							cursor: "pointer",
							"&:hover": { backgroundColor: "#333" },
						}}>
						📁 Create Folder
					</Box>

					{/* Upload File Button */}
					{path?.length ? (
						<Box
							// onClick={handleMenuUploadFiles}
							component="label"
							sx={{
								backgroundColor: "#000",
								color: "#fff",
								padding: "8px 16px",
								borderRadius: "999px",
								cursor: "pointer",
								"&:hover": { backgroundColor: "#333" },
							}}>
							📄 Upload File
							{/* Hidden File Input */}
							<VisuallyHiddenInput
								type="file"
								multiple
								// id="file-upload"
								// ref={fileInputRef}
								// hidden
								onChange={handleUpload}
								// onClick={(e) => (e.target.value = null)}
							/>
						</Box>
					) : (
						""
					)}
				</Box>
			)}
		</Box>
	);
}

export default FloatingButton;
