import { Box, Card, Divider, List, ListItem, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { useState } from "react";
import CustomInputField from "components/ReplenishmentDashboard/ReusableComponents/CustomInputField/CustomInputField";
import { selectOptKeyName } from "components/ReplenishmentDashboard/data";
import { datatypes, muiCustomScrollbar } from "data/globalData";
import moment from "moment";
import {
	logicProfileStoreGroupingAssoc,
	logicProfileWarehouseAssoc,
	storeGroupingtypes,
	storeMastShortNameKey,
	storeMastStoreCodeKey,
	warehouseMappingLogicColumns,
} from "components/ReplenishmentDashboard/LogicProfiles/data";
import WarehouseMappingSection from "components/ReplenishmentDashboard/LogicProfiles/CreateProfileModal/WarehouseMappingSection/WarehouseMappingSection";
import { DragIndicator } from "@mui/icons-material";
import { motion } from "framer-motion";
import DragDropStores from "components/ReplenishmentDashboard/LogicProfiles/CreateProfileModal/DragDropStores/DragDropStores";

const StoreGroupingDisplayWithEdit = ({
	selectedProfile,
	setSelectedProfile,
	storeFullDataList,
	submitFunction,
	disableEdit = false,
}) => {
	let storeFullDataListObj = storeFullDataList?.reduce((acc, curr) => {
		acc[curr?.[storeMastStoreCodeKey]] = curr;
		return acc;
	}, {});

	const [updatedData, setUpdatedData] = useState(selectedProfile);
	const [modalOpen, setModalOpen] = useState(false);

	const handleCloseModal = () => {
		setUpdatedData(selectedProfile);
		setModalOpen(false);
	};
	const handleModalOpen = () => {
		setModalOpen(true);
	};
	const handleFormSubmit = (e) => {
		e.preventDefault();
		let obj = {};
		if (typeof submitFunction === "function") submitFunction(obj, setModalOpen);
	};
	return (
		<Box width="100%" display="flex" flexWrap="wrap" columnGap="1%">
			<Box width="100%" justifyContent="space-between" display="flex" alignItems="center" pt={2} pb={1}>
				<Box flexGrow={1} pr={1}>
					<Typography variant="h6">Store Grouping</Typography>
					<Divider orientation="horizontal" />
				</Box>
				<Button variant="contained" onClick={handleModalOpen} size="small" sx={{ display: disableEdit ? "none" : "block" }}>
					Edit
				</Button>
			</Box>

			<Box display="flex" justifyContent="center" gap={2} p={1}>
				{Object.keys(storeGroupingtypes)
					?.map((row) => storeGroupingtypes[row])
					.map((row) => {
						return {
							...row,
							list: selectedProfile?.[logicProfileStoreGroupingAssoc]?.[row?.key] ?? [],
						};
					})
					?.map(({ name, list, key, ...rest }, index) => (
						<Card key={index} sx={{ width: 250, p: 2, background: grey[50], boxShadow: 1 }}>
							<Typography variant="h6" fontSize={16} fontWeight={600} mb={2}>
								{name}
							</Typography>
							<Box sx={{ height: 250, overflowY: "scroll", ...muiCustomScrollbar }}>
								{list.map((store) => (
									<motion.div
										key={store}
										style={{
											display: "flex",
											alignItems: "center",
											padding: 10,
											marginBottom: 8,
											backgroundColor: "white",
											borderRadius: 4,
											boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.07)",
											cursor: "pointer",
										}}>
										<Tooltip
											title={`${storeFullDataListObj?.[store]?.[storeMastShortNameKey]}(${store})` ?? ""}
											placement="bottom-start">
											<Typography noWrap>{storeFullDataListObj?.[store]?.[storeMastShortNameKey]}</Typography>
										</Tooltip>
									</motion.div>
								))}
							</Box>
						</Card>
					))}
			</Box>

			{modalOpen && (
				<Dialog
					fullWidth
					maxWidth="md"
					open={modalOpen}
					onClose={handleCloseModal}
					component="form"
					onSubmit={handleFormSubmit}>
					<Box width="98%" p={2} boxSizing="border-box" minHeight={300}>
						<Stack width="100%" justifyContent="space-between" flexWrap="wrap" rowGap={1}>
							<Stack direction="row" width="100%" flexWrap="wrap" rowGap={1}>
								<Typography textAlign="center" variant="h6" width="100%">
									Update Store Grouping
								</Typography>
								<Box width="100%">
									<DragDropStores
										setProfileData={setUpdatedData}
										profileData={updatedData}
										storeCodeList={storeFullDataList?.map((row) => row?.[storeMastStoreCodeKey])}
										storeFullDataList={storeFullDataList}
									/>
								</Box>
							</Stack>
						</Stack>
					</Box>

					<DialogActions>
						<Button variant="outlined" onClick={handleCloseModal}>
							Cancel
						</Button>
						<Button autoFocus variant="contained" sx={{ mr: 1 }} type="submit">
							Save
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Box>
	);
};

export default StoreGroupingDisplayWithEdit;
