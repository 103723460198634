import React from "react";
import { store_availability_fields } from "../data";
import { Box, Divider, Typography } from "@mui/material";
import RenderInputField from "components/MboPaymentWorking/CommonComponents/RenderInputFiled/RenderInputFiled";

function StoreAvailabilitySection({ value, setValue }) {
	return (
		<Box p={3} width="60%">
			<Divider>
				<Typography variant="h6">Store Availability</Typography>
			</Divider>
			<Box display="flex" columnGap={"2%"} rowGap="10px" flexWrap="wrap" width={"100%"} justifyContent={"space-around"}>
				{store_availability_fields?.map((row) => {
					return (
						<RenderInputField
							column={row}
							data={value}
							setData={setValue}
							width="40%"
							selectOptObjName={"option_data"}
							// dependencyColData={autoUpdatestoreMainFieldsData}
						/>
					);
				})}
			</Box>
		</Box>
	);
}

export default StoreAvailabilitySection;
