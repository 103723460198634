const parseErrMsgFromError = (error) => {
	if (!error) return "Unknown error occurred.";

	// Handle Sequelize Errors
	if (error.name && error.name.startsWith("Sequelize")) {
		const errorCode = error.original?.code || error.parent?.code || "No error code provided";
		switch (error.name) {
			case "SequelizeValidationError":
				return `Validation Error: ${error.errors.map((err) => err.message).join(", ")}`;
			case "SequelizeUniqueConstraintError":
				return `Unique Constraint Error: ${error.errors.map((err) => err.message).join(", ")}. Code: ${errorCode}`;
			case "SequelizeForeignKeyConstraintError":
				return `Foreign Key Constraint Error: Ensure referenced key exists. Code: ${errorCode}`;
			case "SequelizeDatabaseError":
				return `Database Error: ${error.message}. Code: ${errorCode}`;
			case "SequelizeConnectionError":
				return `Connection Error: ${error.message}. Code: ${errorCode}`;
			case "SequelizeTimeoutError":
				return `Timeout Error: ${error.message}. Code: ${errorCode}`;
			default:
				return `Sequelize Error: ${error.message}. Code: ${errorCode}`;
		}
	}
	// Handle Built-in JavaScript Errors
	if (error instanceof SyntaxError) {
		return `Syntax Error: ${error.message}`;
	} else if (error instanceof ReferenceError) {
		return `Reference Error: ${error.message}`;
	} else if (error instanceof TypeError) {
		return `Type Error: ${error.message}`;
	} else if (error instanceof RangeError) {
		return `Range Error: ${error.message}`;
	} else if (error instanceof URIError) {
		return `URI Error: ${error.message}`;
	} else if (error instanceof EvalError) {
		return `Eval Error: ${error.message}`;
	}

	// Handle Node.js Error Codes
	if (error.code) {
		switch (error.code) {
			case "ENOENT":
				return "File or directory not found.";
			case "EACCES":
				return "Permission denied.";
			case "ECONNREFUSED":
				return "Connection refused.";
			case "ETIMEDOUT":
				return "Operation timed out.";
			case "EADDRINUSE":
				return "Address already in use.";
			case "ECONNRESET":
				return "Connection reset by peer.";
			default:
				return `Error Code: ${error.code} - ${error.message || "No additional information."}`;
		}
	}

	// Generic Fallback for Unhandled Errors
	return `Error: ${error.message || String(error)}`;
};
export default parseErrMsgFromError;
