import { rootProtectedPath } from "data/authData";

export const replenishmentDrawerElements = {
	logic_profiles: {
		name: "Logic Profiles",
		key: "logic_profiles",
		display: true,
	},
	summary: {
		name: "Summary",
		key: "summary",
		display: true,
	},
	upload_files: {
		name: "Upload Files",
		key: "upload_files",
		display: true,
	},
	selected_profile: {
		name: "Selected Profile",
		key: "selected_profile",
		display: false,
	},
};
export const selectOptKeyName = "select_options";
export const replenishmentParamName = "tab";

export const allOption = "ALL";

export const allLogicProfilesPath = `${rootProtectedPath}/${replenishmentDrawerElements.selected_profile.key}`;
