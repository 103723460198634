import RareBoxLogo from "Logos/RareBoxLogo";
import axios from "axios";
import { CustomLinearProgress } from "components/ReusableComponents/CustomLinearProgress/CustomLinearProgress";
import React, { createContext, useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

const MboPaymentContext = createContext();

const MboPaymentProvider = () => {
	useEffect(() => {
		// const fetch = async () => {
		// 	try {
		// 		await axios.get(`/api/attr_master/attr_grp_with_attrs`).then((res) => {
		// 			let obj = res.data?.length ? {} : null;
		// 			let attrArr = [];
		// 			let attrArrObj = {};
		// 			res.data?.forEach((row) => {
		// 				obj[row.group_id] = row;
		// 				row[attrGrpAttrAssocObj].forEach((attr) => {
		// 					attrArr.push(attr);
		// 					attrArrObj = { ...attrArrObj, [attr?.key]: attr };
		// 				});
		// 			});
		// 			console.log(obj, attrArr, "attrArrattrArrattrArr");
		// 			if (obj) {
		// 				setAttrGroupsWithAttr(obj);
		// 				setAllAttributes(attrArr);
		// 				setAllAttributesObj(attrArrObj);
		// 			}
		// 		});
		// 	} catch (error) {
		// 		console.log(error);
		// 	}
		// };
		// fetch();
	}, []);

	return (
		<MboPaymentContext.Provider value={{}}>
			<Outlet />
			{/* <div
				style={{
					width: "250px",
					position: "absolute",
					left: "50%",
					top: "50%",
					transform: "translate(-50%,-50%)",
				}}>
				<RareBoxLogo width="100%" />
				<CustomLinearProgress />
			</div> */}
		</MboPaymentContext.Provider>
	);
};

export const MboPaymentWorkingState = () => {
	return useContext(MboPaymentContext);
};

export default MboPaymentProvider;
