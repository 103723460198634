import { ArrowBackRounded } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { mboPaymentDashboardServices } from "./data";
import DiscountMaster from "./DiscountMaster/DiscountMaster";
// import Attributes from "./Attributes/Attributes";
// import AttributeGroups from "./AttributeGroups/AttributeGroups";

function MboPaymentWorking() {
	const navigate = useNavigate();
	const [currentPath, service] = useOutletContext();
	console.log(currentPath, service);

	const tabComponents = {
		// [mboPaymentDashboardServices.attribute_master.types.attributes.key]: {
		// 	...mboPaymentDashboardServices.attribute_master.types.attributes,
		// 	component: <Attributes />,
		// },
		// [mboPaymentDashboardServices.attribute_master.types.attribute_groups.key]: {
		// 	...mboPaymentDashboardServices.attribute_master.types.attribute_groups,
		// 	component: <AttributeGroups />,
		// },
		[mboPaymentDashboardServices.masters.types.discount_master.key]: {
			...mboPaymentDashboardServices.masters.types.discount_master,
			component: <DiscountMaster />,
		},
	};

	return (
		<Box width={"100%"} p={1}>
			<Box display="flex" alignItems="center" columnGap={1} mt={2}>
				<IconButton onClick={() => navigate(-1)}>
					<ArrowBackRounded />
				</IconButton>
				<Typography variant="h5">{service?.name}</Typography>
			</Box>
			{tabComponents?.[currentPath]?.component}
		</Box>
	);
}

export default MboPaymentWorking;
