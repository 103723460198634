import { arrType, datatypes, inputTypes } from "data/globalData";
import { allOption, selectOptKeyName } from "../data";

export const logicProfileWarehouseAssoc = "logicProfileWarehosusePriority";
export const logicProfileStoreGroupingAssoc = "logicProfileStoreGrouping";
export const storeMastShortNameKey = "STORE";
export const storeMastStoreCodeKey = "SITECODE";

export const logicProfileColumns = {
	profile_name: {
		name: "Profile Name",
		key: "profile_name",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
	},
	brand: {
		name: "Brand",
		key: "brand",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
		[selectOptKeyName]: {
			type: arrType,
			list: [
				{ name: allOption, key: allOption },
				{ name: "RARE RABBIT", key: "RARE RABBIT" },
				{ name: "RAREISM", key: "RAREISM" },
			],
		},
	},
	season: {
		name: "Season",
		key: "season",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
		[selectOptKeyName]: {
			type: arrType,
			list: [
				{ name: "AW-24", key: "AW-24" },
				{ name: "AW-25", key: "AW-25" },
				{ name: "SS-25", key: "SS-25" },
				{ name: "SS-24", key: "SS-24" },
			],
		},
	},
	division: {
		name: "Division",
		key: "division",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
		[selectOptKeyName]: {
			type: arrType,
			list: [
				{ name: allOption, key: allOption },
				{ name: "Apparels", key: "APPARELS" },
				{ name: "Addons", key: "ADDONS" },
				{ name: "Footwear", key: "FOOT WEAR" },
			],
		},
	},
};
export const wearHouses = {
	GGN: { name: "GGN", key: "GGN", pincode: 122506, facility_code: "WH-GGN" },
	JIGANI: { name: "Jigani (BLR)", key: "JIGANI", pincode: 562106, facility_code: "JIGANI-WH" },
	// NELAMANGALA: { name: "Nelamangala (BLR)", key: "NELAMANGALA", pincode: 562123 },
};
export const warehouseMappingLogicColumns = {
	warehouse: {
		name: "Warehouse",
		key: "warehouse",
		input_type: inputTypes.SELECT.key,
		constraints: { NOTNULL: true, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
		[selectOptKeyName]: {
			type: arrType,
			list: Object.keys(wearHouses)?.map((row) => wearHouses[row]),
		},
	},
	p1: {
		name: "Priority 1",
		key: "p1",
		input_type: inputTypes.MULTIPLE_SELECT.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
		[selectOptKeyName]: {
			type: arrType,
			list: [],
		},
	},
	p2: {
		name: "Priority 2",
		key: "p2",
		input_type: inputTypes.MULTIPLE_SELECT.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
		[selectOptKeyName]: {
			type: arrType,
			list: [],
		},
	},
	p3: {
		name: "Priority 3",
		key: "p3",
		input_type: inputTypes.MULTIPLE_SELECT.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
		[selectOptKeyName]: {
			type: arrType,
			list: [],
		},
	},
	p4: {
		name: "Priority 4",
		key: "p4",
		input_type: inputTypes.MULTIPLE_SELECT.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
		[selectOptKeyName]: {
			type: arrType,
			list: [],
		},
	},
};

export const whMappingTypeCol = {
	name: "warehouse Mapping Type",
	key: "wh_mapping_type",
	input_type: inputTypes.ENTER.key,
	constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
	data: {},
	data_type: datatypes.STRING.key,
	sort_no: 1,
	group: "",
	placeholder: "",
	default_value: "",
};
export const daysToreplenishCol = {
	name: "Days to Replenish",
	key: "days_to_replenish",
	input_type: inputTypes.ENTER.key,
	constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
	data: {},
	data_type: datatypes.STRING.key,
	sort_no: 1,
	group: "",
	placeholder: "",
	default_value: 7,
};
export const rosStoreGradeRelationCol = {
	name: "Ros Store Grade Relation",
	key: "ros_store_grade_relation",
	input_type: inputTypes.ENTER.key,
	constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
	data: {},
	data_type: datatypes.STRING.key,
	sort_no: 1,
	group: "",
	placeholder: "",
	default_value: "",
};
export const whMapPriorityTypesColumns = {
	wh_map_zone: {
		name: "Zone",
		key: "wh_map_zone",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
	},
	wh_map_state: {
		name: "State",
		key: "wh_map_state",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
	},
	wh_map_store: {
		name: "Store",
		key: "wh_map_store",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
	},
};
export const storeGradeRosRelationtypes = {
	store_grade_greater_ros: {
		name: "Store Grade > ROS",
		key: "store_grade_greater_ros",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
	},
	ros_greater_store_grade: {
		name: "ROS > Store Grade",
		key: "ros_greater_store_grade",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
	},
};
export const logicProfileToggleColumns = {
	enable_tat: {
		name: "Enable TAT",
		key: "enable_tat",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
	},
	enable_min_max: {
		name: "Enable Min/Max",
		key: "enable_min_max",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
	},
	enable_git: {
		name: "Enable GIT",
		key: "enable_git",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
	},
	enable_mdq: {
		name: "Enable MDQ",
		key: "enable_mdq",

		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
	},
	enable_so: {
		name: "Enable SO",
		key: "enable_so",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
	},
};
export const storeGroupingtypes = {
	to_allocate: {
		name: "To Allocate",
		key: "to_allocate",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
	},
	to_exclude: {
		name: "To Exclude",
		key: "to_exclude",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
	},
	foc_stores: {
		name: "FOC Stores",
		key: "foc_stores",
		input_type: inputTypes.ENTER.key,
		constraints: { NOTNULL: false, DISPLAY: true, UNIQUE: false },
		data: {},
		data_type: datatypes.STRING.key,
		sort_no: 1,
		group: "",
		placeholder: "",
		default_value: "",
	},
};
export const zonesList = {
	EAST: {
		name: "EAST",
		key: "EAST",
	},
	WEST: {
		name: "WEST",
		key: "WEST",
	},
	NORTH: {
		name: "NORTH",
		key: "NORTH",
	},
	SOUTH: {
		name: "SOUTH",
		key: "SOUTH",
	},
	CENTRAL: {
		name: "CENTRAL",
		key: "CENTRAL",
	},
};

const logicToggles = {};
