import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { master_general_information_fields } from "../data";
import RenderInputField from "components/MboPaymentWorking/CommonComponents/RenderInputFiled/RenderInputFiled";

function MasterGeneralInformation({ value, setValue }) {
	return (
		<Box p={3} width="50%">
			<Divider>
				<Typography variant="h6">General Information</Typography>
			</Divider>
			<Box display="flex" columnGap={"2%"} rowGap="10px" flexWrap="wrap" width={"100%"} justifyContent={"space-around"}>
				{master_general_information_fields?.map((row) => {
					return (
						<RenderInputField
							column={row}
							data={value}
							setData={setValue}
							width="32%"
							selectOptObjName={"option_data"}
							// dependencyColData={autoUpdatestoreMainFieldsData}
						/>
					);
				})}
			</Box>
		</Box>
	);
}

export default MasterGeneralInformation;

// import React, { useState } from "react";
// import {
// 	Container,
// 	Grid,
// 	TextField,
// 	Select,
// 	MenuItem,
// 	FormControl,
// 	InputLabel,
// 	Checkbox,
// 	FormControlLabel,
// 	Button,
// 	Table,
// 	TableBody,
// 	TableCell,
// 	TableContainer,
// 	TableHead,
// 	TableRow,
// 	Paper,
// } from "@mui/material";

// const MasterGeneralInformation = () => {
// 	// State variables
// 	const [scheme, setScheme] = useState("");
// 	const [brand, setBrand] = useState("ABC Fashion");
// 	const [accounting, setAccounting] = useState("PROMO");
// 	const [startDate, setStartDate] = useState("");
// 	const [endDate, setEndDate] = useState("");
// 	const [days, setDays] = useState({});
// 	const [discounts, setDiscounts] = useState([
// 		{ buy: 5999, get: 1000 },
// 		{ buy: 7999, get: 1500 },
// 		{ buy: 12999, get: 2500 },
// 		{ buy: 17999, get: 4000 },
// 	]);

// 	return (
// 		<Container>
// 			{/* Header */}
// 			<h2>Configure Schemes</h2>

// 			<Grid container spacing={2}>
// 				{/* Scheme Details */}
// 				<Grid item xs={12} sm={6}>
// 					<TextField fullWidth label="Scheme ID" value="SO4002" disabled />
// 				</Grid>
// 				<Grid item xs={12} sm={6}>
// 					<FormControl fullWidth>
// 						<InputLabel>Brand</InputLabel>
// 						<Select value={brand} onChange={(e) => setBrand(e.target.value)}>
// 							<MenuItem value="ABC Fashion">ABC Fashion</MenuItem>
// 						</Select>
// 					</FormControl>
// 				</Grid>
// 				<Grid item xs={12} sm={6}>
// 					<FormControl fullWidth>
// 						<InputLabel>Accounting</InputLabel>
// 						<Select value={accounting} onChange={(e) => setAccounting(e.target.value)}>
// 							<MenuItem value="PROMO">PROMO</MenuItem>
// 						</Select>
// 					</FormControl>
// 				</Grid>

// 				{/* Date Selection */}
// 				<Grid item xs={12} sm={3}>
// 					<TextField
// 						fullWidth
// 						label="Start Date"
// 						type="date"
// 						InputLabelProps={{ shrink: true }}
// 						value={startDate}
// 						onChange={(e) => setStartDate(e.target.value)}
// 					/>
// 				</Grid>
// 				<Grid item xs={12} sm={3}>
// 					<TextField
// 						fullWidth
// 						label="End Date"
// 						type="date"
// 						InputLabelProps={{ shrink: true }}
// 						value={endDate}
// 						onChange={(e) => setEndDate(e.target.value)}
// 					/>
// 				</Grid>

// 				{/* Applicable Days */}
// 				<Grid item xs={12}>
// 					<h4>Applicable Days</h4>
// 					{["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
// 						<FormControlLabel
// 							key={day}
// 							control={<Checkbox checked={!!days[day]} onChange={() => setDays({ ...days, [day]: !days[day] })} />}
// 							label={day}
// 						/>
// 					))}
// 				</Grid>

// 				{/* Discount Slab */}
// 				<Grid item xs={12}>
// 					<h4>Scheme Discount Slab</h4>
// 					<TableContainer component={Paper}>
// 						<Table>
// 							<TableHead>
// 								<TableRow>
// 									<TableCell>BUY Value</TableCell>
// 									<TableCell>GET Value</TableCell>
// 								</TableRow>
// 							</TableHead>
// 							<TableBody>
// 								{discounts.map((row, index) => (
// 									<TableRow key={index}>
// 										<TableCell>{row.buy}</TableCell>
// 										<TableCell>{row.get}</TableCell>
// 									</TableRow>
// 								))}
// 							</TableBody>
// 						</Table>
// 					</TableContainer>
// 				</Grid>

// 				{/* Submit Button */}
// 				<Grid item xs={12}>
// 					<Button variant="contained" color="primary">
// 						Create New Scheme
// 					</Button>
// 				</Grid>
// 			</Grid>
// 		</Container>
// 	);
// };

// export default MasterGeneralInformation;
