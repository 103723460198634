import { Collapse, List } from "@mui/material";
import Box from "@mui/material/Box";
import { AuthState } from "context/AuthContext";
import MenuIcon from "@mui/icons-material/Menu";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";

import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { grey } from "@mui/material/colors";

import { useEffect, useState } from "react";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";
import Navbar from "./Navbar/Navbar";
import { styled, useTheme } from "@mui/material/styles";
import CategoryIcon from "@mui/icons-material/Category";
import { rootProtectedPath, services } from "data/authData";
import { ExpandLess, ExpandMore, Inbox, StarBorder } from "@mui/icons-material";
const drawerWidth = 240;
const miniDrawerWidth = 85;

const serviceIconStyle = { color: grey[300], sx: { transform: "scale(1.3)", p: 2 } };

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

const DrawerListItem = ({ service, currentPage, rootPath }) => {
	const [dropDown, setDropDown] = useState(service?.types && service?.types[currentPage] ? true : false);
	const navigate = useNavigate();
	const handleDropdownSelect = () => {
		setDropDown(!dropDown);
	};
	const handleServiceClick = (value) => {
		navigate(`/${rootProtectedPath}/${rootPath}/${value}`);
	};
	if (service?.types) {
		return (
			<>
				<ListItemButton onClick={handleDropdownSelect}>
					<ListItemIcon>{service.icon}</ListItemIcon>
					<ListItemText
						primary={service.name}
						primaryTypographyProps={{
							// color: "primary",
							fontWeight: "medium",
							variant: "body1",
							color: grey[600],
						}}
					/>
					{dropDown ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={dropDown} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						{Object.keys(service.types)
							.filter((row) => service.types[row].display === true)
							.map((row) => {
								const data = service.types[row];
								return (
									<ListItemButton
										sx={{ pl: 2, height: 40 }}
										selected={currentPage === data.key}
										onClick={() => handleServiceClick(data.key)}>
										<ListItemIcon sx={{ transform: "scale(0.8)" }}>{data.icon}</ListItemIcon>
										<ListItemText
											primary={data.name}
											primaryTypographyProps={{
												// color: "primary",
												fontWeight: "medium",
												variant: "body2",
												color: grey[900],
											}}
										/>
									</ListItemButton>
								);
							})}
					</List>
				</Collapse>
			</>
		);
	} else
		return (
			<ListItemButton onClick={() => handleServiceClick(service.key)} selected={currentPage === service.key}>
				<ListItemIcon>{service.icon}</ListItemIcon>
				<ListItemText
					primary={service.name}
					primaryTypographyProps={{
						// color: "primary",
						fontWeight: "medium",
						variant: "body1",
					}}
				/>
			</ListItemButton>
		);
};
const DashboardWithMiniCollapsableDrawer = ({ drawerList, searchParamName, navSpace, service, pathName, rootPath }) => {
	// drawer open/close
	const [open, setOpen] = useState(false);
	const [drawerElements, setDrawerElements] = useState();

	const theme = useTheme();

	const { user } = AuthState();

	const SetDrawerElements = () => {
		let service;
		setDrawerElements(
			Object.keys(drawerList)?.reduce((acc, row) => {
				if (drawerList[row].display) acc.push(drawerList[row]);
				if (row === pathName) service = { ...drawerList[row] };
				return acc;
			}, [])
		);

		setOpen(false);
	};

	useEffect(() => {
		SetDrawerElements();
	}, []);

	return (
		<Box width="100%">
			<Navbar />
			<Box display="flex">
				<Box
					sx={{
						width: (theme) => `calc(${theme.spacing(7)} + 1px)`,
						[theme.breakpoints.up("sm")]: {
							width: `calc(${theme.spacing(8)} + 1px)`,
						},
					}}>
					<Drawer variant="permanent" open={open} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
						<Box
							display="flex"
							justifyContent={open ? "flex-start" : "center"}
							alignItems="center"
							pt={9}
							pl={open ? 2 : 0}
							pb={1}>
							{service?.icon}
							<Typography pl={3} variant="h6" display={open ? "block" : "none"}>
								{service.name}
							</Typography>
						</Box>

						<Divider />
						<List>
							{drawerElements &&
								Object.keys(drawerElements)?.map((key, index) => {
									const service = drawerElements[key];
									return (
										<DrawerListItem
											key={service.key}
											service={service}
											currentPage={pathName}
											rootPath={rootPath}
										/>
									);
								})}
						</List>
					</Drawer>
				</Box>

				<Box width={`calc(100% - ${miniDrawerWidth}px)`}>
					<Box pt={navSpace ? navSpace : 0} />
					{<Outlet context={[pathName, service]} />}
				</Box>
			</Box>
		</Box>
	);
};

export default DashboardWithMiniCollapsableDrawer;
